import { useEffect } from 'react';
import { Box } from '@mui/joy';

import CustomForm from '../../components/CustomForm/CustomForm';
import ModernHeader from '../../components/Header/ModernHeader';

type Props = {
  currentTab: number;
};

export default function EvaluatePage({ currentTab }: Props) {
  useEffect(() => {}, [currentTab]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ModernHeader />
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <CustomForm currentTab={currentTab} />
      </Box>
    </Box>
  );
}
