import { useState } from 'react';
import { Box, Divider, Modal, ModalClose, Tooltip, Typography } from '@mui/joy';
import ModalDialog, { ModalDialogProps } from '@mui/joy/ModalDialog';
import ModalOverflow from '@mui/joy/ModalOverflow';

const Helper = [
  {
    id: '0',
    name: 'Forma prowadzenia działalności',
    description:
      'Proszę wybrać jedną z form klikając kursorem na kwadrat z rozwinięciem po prawej stronie kolumny „wybierz z listy”.',
  },
  {
    id: '1',
    name: 'Branża',
    description:
      'Wybieramy branże z listy branżowo-sektorowej najlepiej charakteryzującą badany biznes',
  },
  {
    id: '2',
    name: 'Wielkość zatrudnienia łącznie z właścicielami (UoP, B2B)',
    description:
      'Tutaj zaznaczamy odpowiedni przedział pracowników  zatrudnionych na podstawie Umowy o Pracę (UoP), B2B oraz pozostałych form łącznie z właścicielem/ właścicielami firmy. Info wg stanu na 31.12.ostatniego roku obrachunkowego.',
  },
  {
    id: '3',
    name: 'Siedziba główna',
    description:
      'Tutaj wybieramy z listy województwo w którym znajduje się siedziba firmy z aktualnych dokumentów rejestrowych. ',
  },
  {
    id: '4',
    name: 'Oddziały',
    description:
      'Jeśli firma poza siedzibą posiada minimum 1 oddział na terenie Polski, wybieramy Tak. Jeśli nie posiada żadnego oddziału- wybieramy Nie',
  },
  {
    id: '5',
    name: 'Oddziały poza PL (min 1 w UE)',
    description:
      'Wybieramy Tak, jeśli firma posiada co najmniej jeden oddział na terenie kraju w Unii Europejskiej (UE). Jeśli nie posiada- wybieramy Nie',
  },
  {
    id: '6',
    name: 'Oddział poza UE (minimum 1 poza UE):',
    description:
      'Wybieramy Tak, jeśli firma posiada co najmniej jeden oddział na terenie dowolnego kraju poza Unią Europejską (UE). Jeśli nie posiada-wybieramy Nie',
  },
  {
    id: '7',
    name: 'Kredyt obrotowy w rachunku firmowym',
    description:
      'Wybieramy Tak, jeśli firma posiadała w ostatnim roku obrachunkowym minimum 1 kredyt obrotowy w rachunku firmowym. Zaznaczamy Nie, jeśli nie posiadała żadnego kredytu obrotowego',
  },
  {
    id: '8',
    name: 'Obsługa księgowa',
    description:
      'Wybieramy zewnętrzna jeśli firma ma umowę na prowadzenie księgowości z podmiotem zewnętrznym, wybieramy wewnętrzna, jeśli osoba lub osoby są zatrudnione w firmie na podstawie UoP lub na wyłączność na podstawie B2B. W przypadku obu form, wpisujemy mieszana.',
  },
  {
    id: '9',
    name: 'Import produktów, usług, lub komponentów',
    description:
      'Wybieramy Tak, jeśli firma importuje produkty, usługi, lub komponenty lub wybieramy Nie, jeśli nie importuje produktów, usług lub komponentów.',
  },
  {
    id: '10',
    name: 'Realizacja zamówień i/lub sprzedaży w kanale internetowym (e-commerce)',
    description:
      'Wybieramy Tak, jeśli sprzedaż lub realizacja zamówień jest realizowana przy użyciu kanału e-commerce (własna strona, market place, itp.). Wybieramy Nie, jeśli ten kanał nie jest używany. ',
  },
];

const SecondHelper = [
  {
    id: '0',
    name: 'Rok',
    description:
      'Prosimy o wprowadzenie danych księgowych z RZiS lub innego dokumentu księgowego za dany rok obrachunkowy.',
  },
  {
    id: '1',
    name: 'Przychody netto ze sprzedaży',
    description:
      'Prosimy o wprowadzenie danych z RZiS lub innego dokumentu księgowego w PLN.',
  },
  {
    id: '2',
    name: 'Zysk operacyjny',
    description:
      'Prosimy wprowadzenie danych z RZiS lub innego dokumentu księgowego w PLN.',
  },
  {
    id: '3',
    name: 'Amortyzacja',
    description:
      'Prosimy o wprowadzenie danych z RZiS lub innego dokumentu księgowego w PLN. Jeśli nie odnotowano amortyzacji, prosimy o wprowadzenie wartości 0 (zero).',
  },
  {
    id: '4',
    name: 'Środki pieniężne w kasie i na rachunkach',
    description:
      'Prosimy o wprowadzenie sumy wszystkich środków na wszystkich kontach i w kasie ( o ile występuje) firmy, zgodnie z zapisami na dzień 31.12 ostatniego roku obrachunkowego w walucie PLN w PLN.',
  },
  {
    id: '5',
    name: 'Zobowiązania',
    description:
      'Prosimy o wprowadzenie wartości sumy zobowiązań zgodnie z zapisami na dzień 31.12 ostatniego roku obrachunkowego w PLN.',
  },
  {
    id: '6',
    name: 'Trzymiesięczny budżet płac (UoP)',
    description:
      'Dotyczy tylko zatrudnionych na podstawie Umowy o Pracę (UoP). Prosimy o wprowadzenie wartości wynikającej z wynagrodzeń bez premii i dodatków w PLN.',
  },
  {
    id: '7',
    name: 'Należności nieprzeterminowane (do 90 dni po dacie na FV)',
    description:
      'Prosimy o wprowadzenie wartości zgodnie z zapisami na dzień 31.12 ostatniego roku obrachunkowego w PLN.',
  },
  {
    id: '8',
    name: 'Zapasy',
    description:
      'Prosimy o wprowadzenia wartości zapasów zgodnie z zapisami na dzień 31.12 ostatniego roku obrachunkowego w PLN.',
  },
  {
    id: '9',
    name: 'Środki trwałe (Suma)',
    description:
      'Prosimy o wprowadzenie wartości w punktach od a) do e) zgodnie z zapisami na dzień 31.12 ostatniego roku obrachunkowego w PLN.',
  },
];

export default function GuidebookModal() {
  const [layout, setLayout] = useState<ModalDialogProps['layout'] | undefined>(
    undefined
  );

  return (
    <div className='bg-white'>
      <Tooltip
        variant='solid'
        color='primary'
        title='Kliknij by uzyskać więcej informacji o wymaganych polach'
      >
        <div
          onClick={() => setLayout('center')}
          className='p-4 text-white button flex items-center transition-all justify-center disabled:opacity-50 disabled:shadow-none disabled:cursor-not-allowed w-full rounded-lg font-semibold bg-slate-700 mt-[1rem] cursor-pointer'
        >
          <span>Przewodnik</span>
        </div>
      </Tooltip>
      <Modal
        open={!!layout}
        onClose={() => {
          setLayout(undefined);
        }}
      >
        <ModalOverflow className='py-0'>
          <ModalDialog aria-labelledby='modal-dialog-overflow' layout={layout}>
            <Typography id='modal-dialog-overflow' level='h2'></Typography>
            <div className='min-w-[300px] max-w-[1280px] overflow-y-auto'>
              <Typography
                component='h2'
                id='close-modal-title'
                level='h2'
                textColor='inherit'
                fontWeight='lg'
                textAlign='center'
                sx={{ mb: 2 }}
                className='min-[2000px]:mb-10'
              >
                Przewodnik
              </Typography>
              <ModalClose className='absolute right-0 top-0' />
              <div className='flex flex-col sm:flex-row overflow-scroll-y'>
                <div className='flex flex-col gap-4'>
                  {Helper.map((item) => (
                    <div key={item.id} className='flex flex-col'>
                      <span className='font-bold'>{item.name}</span>
                      <span>{item.description}</span>
                    </div>
                  ))}
                </div>
                <Divider orientation='vertical' sx={{ mx: 5 }} />
                <div className='flex flex-col gap-4'>
                  {SecondHelper.map((item) => (
                    <div key={item.id} className='flex flex-col'>
                      <span className='font-bold'>{item.name}</span>
                      <span>{item.description}</span>
                    </div>
                  ))}
                </div>
              </div>
              <Box
                sx={{
                  width: '100%',
                  padding: '1rem',
                  borderRadius: '0.5rem',
                  textDecoration: 'none',
                  backgroundColor: '#85bb65',
                  '&:hover': {
                    backgroundColor: '#85bb65 !important',
                    bgcolor: '#85bb65 !important',
                    background: '#85bb65 !important',
                  },
                  '&:active': {
                    boxShadow: 'none !important',
                    backgroundColor: '#85bb65 !important',
                  },
                }}
                className='cursor-pointer text-white button flex items-center transition-all justify-center disabled:opacity-50 disabled:shadow-none disabled:cursor-not-allowed mt-10 w-full rounded-lg font-semibold md:mt-8 min-[2000px]:mt-5 bg-[#34A853]'
                onClick={() => setLayout(undefined)}
              >
                Zacznij wypełniać formularze
              </Box>
            </div>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </div>
  );
}
