import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import {
  fetchSecondFormData,
  prefillSecondFormData,
} from '../../CustomForm/ApiService';
import { SecondFormInputs } from '../../CustomForm/FormsTypes';

export default function NetRevenuesFromSales(data: any) {
  const [chartData, setChartData] = useState<[]>();
  const formData = data.data as SecondFormInputs;

  const orientation = false;
  function formatCurrency(number: number) {
    //number = number * 1000;
    const formatter = new Intl.NumberFormat('pl-PL', {
      // style: 'currency',
      // currency: 'PLN',
      minimumFractionDigits: 0,
    });
    return formatter.format(number);
  }
  function formatFirstNumber(number: number) {
    const numberString: string = number.toFixed(2);
    const formatter = new Intl.NumberFormat('pl-PL', {
      minimumFractionDigits: 2,
    });
    return formatter.format(Number(numberString));
  }
  useEffect(() => {
    // console.log('data from chart', formData);
  }, []);

  // Your trendline calculation function
  const calculateTrendlineData = (data: number[]) => {
    // Replace this with your own logic to calculate the trendline data.
    // For a simple linear trendline, you can use linear regression.
    // Here's a simplified example:

    const n = data.length;
    let sumX = 0;
    let sumY = 0;
    let sumXY = 0;
    let sumX2 = 0;

    for (let i = 0; i < n; i++) {
      sumX += i + 1; // X values (1, 2, 3, ...)
      sumY += data[i]; // Y values
      sumXY += (i + 1) * data[i]; // X * Y
      sumX2 += Math.pow(i + 1, 2); // X^2
    }

    const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - Math.pow(sumX, 2));
    const intercept = (sumY - slope * sumX) / n;

    const trendlineData = [];

    for (let i = 0; i < n; i++) {
      trendlineData.push(slope * (i + 1) + intercept);
    }

    return trendlineData;
  };

  // Data for the bar chart
  const barChartSeries = [
    {
      name: 'Przychody netto ze sprzedaży',
      data: [
        formData.net_revenue_2020,
        formData.net_revenue_2021,
        formData.net_revenue_2022,
      ],
      type: 'bar',
      color: '#FFA500',
    },
  ];

  // Data for the trendline chart
  const trendlineSeries = [
    {
      name: 'Linia trendu',
      data: calculateTrendlineData([
        formData.net_revenue_2020,
        formData.net_revenue_2021,
        formData.net_revenue_2022,
      ]),

      type: 'line',
      marker: {
        show: false,
      },
      color: '#000000',
    },
  ];

  const options: ApexOptions = {
    chart: {
      id: 'combined-chart',
      animations: {
        speed: 1800,
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: orientation,
      },
    },
    xaxis: {
      categories: ['2023', '2022', '2021'],
    },
    yaxis: {
      decimalsInFloat: 0,
      labels: {
        formatter: function (value) {
          return formatFirstNumber(value) + ' zł';
        },
      },
    },
    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: 'vertical',
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    tooltip: {
      shared: false,
      intersect: false,
    },
  };

  return (
    <div>
      {/* Combined Chart */}
      <Chart
        options={options}
        series={[...barChartSeries, ...trendlineSeries]}
        type='line'
        width='100%'
      />
    </div>
  );
}
