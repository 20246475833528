export const firstFormOptions = [
  {
    title: 'Forma prowadzenia działalności',
    key: 'form_of_business',
    answers: [
      { value: 'jdg', label: 'JDG' },
      { value: 'spolka-cywilna', label: 'Spółka cywilna' },
      { value: 'spolka-z-o-o', label: 'Spółka z o.o.' },
      {
        value: 'spolka-z-o-o-Spolka-komandytowa',
        label: 'Spółka z o.o. Spólka komandytowa',
      },
      { value: 'spolka-akcyjna', label: 'Spółka Akcyjna' },
      { value: 'spolka-jawna', label: 'Spółka Jawna' },
      { value: 'spolka-partnerska', label: 'Spółka Partnerska' },
      { value: 'spolka-komandytowa', label: 'Spółka komandytowa' },
      {
        value: 'spolka-komandytowo-akcyjna',
        label: 'Spółka komandytowo-akcyjna',
      },
    ],
    hint: 'Proszę wybrać jedną z form klikając kursorem na kwadrat z rozwinięciem po prawej stronie kolumny „wybierz z listy”',
    placeholder: 'Wybierz typ spółki',
  },
  {
    title: 'Branża główna',
    key: 'sector',
    answers: [
      {
        value: 'przemysl-ciezki',
        label: 'Przemysł ciężki: produkcja stali, aluminium, maszyn',
        category: 'SEKTOR PRZEMYSŁOWY',
      },
      {
        value: 'przemysl-motoryzacyjny',
        label: 'Przemysł motoryzacyjny: produkcja pojazdów i podzespołów',
        category: 'SEKTOR PRZEMYSŁOWY',
      },
      {
        value: 'przemysl-chemiczny',
        label:
          'Przemysł chemiczny: produkcja chemikaliów, nawozów, środków agrochemicznych ',
        category: 'SEKTOR PRZEMYSŁOWY',
      },
      {
        value: 'przemysl-spozywczy',
        label: 'Przemysł spożywczy: produkcja żywności i napojów',
        category: 'SEKTOR PRZEMYSŁOWY',
      },
      {
        value: 'przemysl-farmaceutyczny',
        label:
          'Przemysł farmaceutyczny: produkcja leków i wyrobów farmaceutycznych',
        category: 'SEKTOR PRZEMYSŁOWY',
      },
      {
        value: 'przemysl-elektroniczny',
        label:
          'Przemysł elektroniczny: produkcja urządzeń elektronicznych i komponentów ',
        category: 'SEKTOR PRZEMYSŁOWY',
      },
      {
        value: 'przemysl-drzewny-i-papierniczy',
        label:
          'Przemysł drzewny i papierniczy: produkcja mebli, papieru i wyrobów z drewna',
        category: 'SEKTOR PRZEMYSŁOWY',
      },
      {
        value: 'przemysl-tekstylny-i-odziezowy',
        label:
          'Przemysł tekstylny i odzieżowy: produkcja tekstyliów, odzieży i obuwia',
        category: 'SEKTOR PRZEMYSŁOWY',
      },
      {
        value: 'finanse-i-bankowosc',
        label:
          'Finanse i bankowość: banki, instytucje finansowe, usługi ubezpieczeniowe',
        category: ' SEKTOR USŁUG',
      },
      {
        value: 'technologie-informatyczne',
        label:
          'Technologie informatyczne: firmy IT, produkcja oprogramowania, usługi informatyczne',
        category: ' SEKTOR USŁUG',
      },
      {
        value: 'uslugi-konsultingowe',
        label:
          'Usługi konsultingowe: doradztwo biznesowe, konsulting strategiczny',
        category: 'SEKTOR USŁUG',
      },
      { value: 'e-commerce', label: 'E-commerce', category: ' SEKTOR USŁUG' },
      {
        value: 'handel-detaliczny-i-hurtowy',
        label:
          'Handel detaliczny i hurtowy: sklepy, sieci handlowe, dystrybucja towarów, omnichannel',
        category: 'SEKTOR USŁUG',
      },
      {
        value: 'transport-i-logistyka',
        label:
          'Transport i logistyka: przewóz osób, transport towarów, logistyka magazynowa',
        category: ' SEKTOR USŁUG',
      },
      {
        value: 'gastronomia-i-turystyka',
        label:
          'Gastronomia i turystyka: restauracje, hotele, agencje turystyczne',
        category: ' SEKTOR USŁUG',
      },
      {
        value: 'opieka-zdrowotna',
        label: 'Opieka zdrowotna: szpitale, przychodnie, firmy medyczne',
        category: ' SEKTOR USŁUG',
      },
      {
        value: 'edukacja',
        label: 'Edukacja: szkoły, uczelnie, kursy i szkolenia',
        category: ' SEKTOR USŁUG',
      },
      {
        value: 'media-i-rozrywka',
        label: 'Media i rozrywka: TV, radio, kinematografia, produkcja treści',
        category: ' SEKTOR USŁUG',
      },
      {
        value: 'internet-portale-media-online',
        label: 'Internet, portale, media online  ',
        category: ' SEKTOR USŁUG',
      },
      {
        value: 'uslugi-projektowe-architektura-i-nieruchomosci',
        label:
          'Usługi projektowe, architektura i nieruchomości: pracownie, agencje nieruchomości  ',
        category: ' SEKTOR USŁUG',
      },
      {
        value: 'produkcja-rolno-spozywcza',
        label: 'Produkcja rolno-spożywcza: uprawa roślin, hodowla zwierząt',
        category: 'SEKTOR ROLNICTWA ',
      },
      {
        value: 'rybolostwo',
        label: 'Rybołóstwo: połów ryb i owoców morza',
        category: 'SEKTOR ROLNICTWA ',
      },
      {
        value: 'lesnictwo',
        label: 'Leśnictwo: gospodarka leśna, produkcja drewna',
        category: 'SEKTOR ROLNICTWA ',
      },
      {
        value: 'energetyka-konwencjonalna',
        label: 'Energetyka konwencjonalna: elektrownie węglowe, gazowe',
        category: 'SEKTOR ENERGETYCZNY  ',
      },
      {
        value: 'energetyka-odnawialna',
        label: 'Energetyka odnawialna: energia wiatrowa, słoneczna, biomasa',
        category: 'SEKTOR ENERGETYCZNY  ',
      },
      {
        value: 'wydobycie-i-przetwarzanie-surowcow-energetycznych',
        label:
          'Wydobycie i przetwarzanie surowców energetycznych: węgiel gaz ziemny, itp ',
        category: 'SEKTOR ENERGETYCZNY',
      },

      {
        value: 'budownictwo-mieszkaniowe',
        label: 'Budownictwo mieszkanIowe: budowa mieszkań i domów',
        category: 'SEKTOR BUDOWLANY',
      },
      {
        value: 'budownictwo-infrastrukturalne',
        label:
          'Budownictwo infrastrukturalne: drogi, mosty, lotniska, kolejnictwo',
        category: 'SEKTOR BUDOWLANY',
      },
      {
        value: 'budownictwo-komercyjne-i-przemyslowe',
        label:
          'Budownictwo komercyjne i przemysłowe: budowa biurowców, centrów handlowych, ',
        category: 'SEKTOR BUDOWLANY',
      },
    ],
    hint: 'Wybieramy branże z listy branżowo-sektorowej najlepiej charakteryzującą badany biznes',
    placeholder: 'Wybierz branżę',
  },
  {
    title: 'Wielkość zatrudnienia łącznie z właścicielami (UoP, B2B) ',
    key: 'employment_size',
    answers: [
      { value: 'do-9', label: 'do 9' },
      { value: '10-50', label: 'od 10 do 50' },
      { value: '51-250', label: '51-250' },
    ],
    hint: 'Tutaj zaznaczamy odpowiedni przedział pracowników zatrudnionych na podstawie Umowy o Pracę (UoP), B2B oraz pozostałych form łącznie z właścicielem/ właścicielami firmy. Info wg stanu na 31 grudnia ostatniego roku obrachunkowego.',
    placeholder: 'Wybierz wielkośc organizacji',
  },
  {
    title: 'Siedziba główna',
    key: 'headquarter',
    answers: [
      { value: 'mazowieckie', label: 'Mazowieckie' },
      { value: 'slaskie', label: 'Śląskie' },
      { value: 'Pomorskie', label: 'Pomorskie' },
      { value: 'dolnoslaskie', label: 'Dolnośląskie' },
      { value: 'wielkopolskie', label: 'Wielkopolskie' },
      { value: 'opolskie', label: 'Opolskie' },
      { value: 'malopolskie', label: 'Małopolskie' },
      { value: 'lubuskie', label: 'Lubuskie' },
      { value: 'zachodnio-pomorskie', label: 'Zachodniopomorskie' },
      { value: 'lodzkie', label: 'Łódzkie' },
      { value: 'kujawsko-pomorskie', label: 'Kujawsko-Pomorskie' },
      { value: 'podlaskie', label: 'Podlaskie' },
      { value: 'lubelskie', label: 'Lubelskie' },
      { value: 'pomorskie', label: 'Pomorskie' },
      { value: 'podkarpackie', label: 'Podkarpackie' },
      { value: 'warminsko-mazurskie', label: 'Warmińsko-Mazurskie' },
      { value: 'swietokrzyskie', label: 'Świętokrzyskie' },
    ],
    hint: 'Tutaj wybieramy z listy województwo w którym znajduje się siedziba firmy z aktualnych dokumentów rejestrowych',
    placeholder: 'Siedziba główna',
  },
  {
    title: 'Czy firma posiada oddziały?',
    key: 'departments',
    answers: [
      { value: 'true', label: 'Tak' },
      { value: 'false', label: 'Nie' },
    ],
    hint: 'Jeśli firma poza siedzibą posiada minimum 1 oddział na terenie Polski, wybieramy Tak. Jeśli nie posiada żadnego oddziału- wybieramy Nie',
    placeholder: 'Oddziały',
  },
  {
    title: 'Czy firma posiada oddziały poza PL (min 1 w UE)?',
    key: 'eu_departments',
    answers: [
      { value: 'true', label: 'Tak' },
      { value: 'false', label: 'Nie' },
    ],
    hint: 'Wybieramy Tak, jeśli firma posiada co najmniej jeden oddział na terenie kraju w Unii Europejskiej (UE). Jeśli nie posiada- wybieramy Nie',
    placeholder: 'Oddziały poza PL?',
  },
  {
    title: 'Czy firma posiada oddział poza UE (minimum 1 poza UE) ',
    key: 'intercontinental_departments',
    answers: [
      { value: 'true', label: 'Tak' },
      { value: 'false', label: 'Nie' },
    ],
    hint: 'Wybieramy Tak, jeśli firma posiada co najmniej jeden oddział na terenie dowolnego kraju poza Unią Europejską (UE). Jeśli nie posiada-wybieramy Nie',
    placeholder: 'Oddziały poza UE?',
  },
  {
    title: 'Rynek',
    key: 'market',
    answers: [
      { value: 'rynek-w-PL', label: 'Rynek w PL' },
      { value: 'rynek-w-EU', label: 'Rynek w EU (min 10% sprzedaży)' },
      { value: 'rynek-poza-EU', label: 'Rynek poza EU' },
    ],
    hint: 'Wybieramy rynek w PL, jeśli firma nie realizuje exportu. Wybieramy rynek w EU jeśli wartość exportu w ostatnim roku obrachunkowym, stanowiła co najmniej 10% wartości sprzedaży. Wybieramy Rynek poza EU, jeśli firma zrealizowała w ostatnim roku obrachunkowym, jakikolwiek obrót z podmiotem spoza krajów Unii Europejskiej',
    placeholder: 'Wybierz rynek',
  },
  {
    title: 'Kredyt obrotowy w rachunku firmowym?',
    key: 'capital_debit',
    answers: [
      { value: 'true', label: 'Tak' },
      { value: 'false', label: 'Nie' },
    ],
    hint: 'Wybieramy Tak, jeśli firma posiadała w ostatnim roku obrachunkowym minimum 1 kredyt obrotowy w rachunku firmowym. Zaznaczamy Nie, jeśli nie posiadała żadnego kredytu obrotowego',
    placeholder: 'Kredyt obrotowy?',
  },
  {
    title: 'Obsługa księgowa',
    key: 'accounting',
    answers: [
      { value: 'wewnetrzna', label: 'Wewnętrzna' },
      { value: 'zewnetrzna', label: 'Zewnętrzna' },
      { value: 'mieszana', label: 'Mieszana' },
    ],
    hint: 'Wybieramy zewnętrzna jeśli firma ma umowę na prowadzenie księgowości z podmiotem zewnętrznym, wybieramy wewnętrzna, jeśli osoba lub osoby są zatrudnione w firmie na podstawie UoP lub na wyłączność na podstawie B2B. W przypadku obu form, wpisujemy mieszana',
    placeholder: 'Obsługa księgowa',
  },
  {
    title: 'Działalnosc koncesjonowana',
    key: 'licensed_activity',
    answers: [
      { value: 'true', label: 'Tak' },
      { value: 'false', label: 'Nie' },
    ],
    hint: 'Jeśli firma prowadzi jakąkolwiek działalność na podstawie zezwolenia i/lub koncesji, wybieramy Tak. Jeśli nie prowadzi, wybieramy Nie. ',
    placeholder: 'Działalność koncesjonowana',
  },
  {
    title: 'Import produktów, usług, lub komponentów? ',
    key: 'company_import',
    answers: [
      { value: 'true', label: 'Tak' },
      { value: 'false', label: 'Nie' },
    ],
    hint: 'Wybieramy Tak, jeśli firma importuje produkty, usługi, lub komponenty lub wybieramy Nie, jeśli nie importuje produktów, usług lub komponentów.',
    placeholder: 'Import produktów, usług, lub komponentów?',
  },
  {
    title: 'Realizacja zamówień i/lub sprzedaży w kanale internetowym',
    key: 'ecommerce',
    answers: [
      { value: 'true', label: 'Tak' },
      { value: 'false', label: 'Nie' },
    ],
    hint: 'Wybieramy Tak, jeśli sprzedaż lub realizacja zamówień jest realizowana przy użyciu kanału e-commerce (własna strona, market place, itp.). Wybieramy Nie, jeśli ten kanał nie jest używany.',
    placeholder: 'Realizacja zamówień i/lub sprzedaży e-commerce?',
  },
];

function createData(
  name: string,
  form_id_1: string,
  form_id_2: string,
  form_id_3: string,
  hint?: string,
  suma?: boolean,
  show?: boolean
) {
  return {
    name,
    form_id_1,
    form_id_2,
    form_id_3,
    hint,
    suma,
    show,
  };
}

export const secondFormOptions = [
  createData(
    'Przychody netto ze sprzedaży w PLN',
    'net_revenue_2022',
    'net_revenue_2021',
    'net_revenue_2020',
    'prosimy o wprowadzenie danych z RZiS lub innego dokumentu księgowego w PLN.'
  ),
  createData(
    'Zysk operacyjny w PLN',
    'operating_profit_2022',
    'operating_profit_2021',
    'operating_profit_2020',
    'prosimy wprowadzenie danych z RZiS lub innego dokumentu księgowego w PLN.'
  ),
  createData(
    'Amortyzacja w PLN',
    'depreciation_2022',
    'depreciation_2021',
    'depreciation_2020',
    'prosimy o wprowadzenie danych z RZiS lub innego dokumentu księgowego w PLN. Jeśli nie odnotowano amortyzacji, prosimy o wprowadzenie wartości 0 (zero). '
  ),
  createData(
    'Środki pieniężne w kasie i na rachunkach w PLN',
    'total_cash_2022',
    'total_cash_2021',
    'total_cash_2020',
    'prosimy o wprowadzenie sumy wszystkich środków na wszystkich kontach i w kasie ( o ile występuje) firmy, zgodnie z zapisami na dzień 31.12 ostatniego roku obrachunkowego w PLN.',
    false,
    false
  ),
  createData(
    'Zobowiązania w PLN',
    'commitments_2022',
    'commitments_2021',
    'commitments_2020',
    'prosimy o wprowadzenie wartości sumy zobowiązań zgodnie z zapisami na dzień 31.12 ostatniego roku obrachunkowego w PLN.'
  ),
  createData(
    'Trzymiesięczny budżet płac (UoP) w PLN',
    'three_month_salary_budget_2022',
    'three_month_salary_budget_2021',
    'three_month_salary_budget_2020',
    'dotyczy tylko zatrudnionych na podstawie Umowy o Pracę (UoP). Prosimy o wprowadzenie wartości wynikającej z wynagrodzeń bez premii i dodatków w PLN.',
    false,
    false
  ),
  createData(
    'Należności nieprzeterminowane (do 90 dni po dacie na FV) w PLN',
    'receivables_not_overdue_2022',
    'receivables_not_overdue_2021',
    'receivables_not_overdue_2020',
    'prosimy o wprowadzenie wartości zgodnie z zapisami na dzień 31.12 ostatniego roku obrachunkowego w PLN.'
  ),
  createData(
    'Zapasy w PLN',
    'supplies_2022',
    'supplies_2021',
    'supplies_2020',
    'prosimy o wprowadzenia wartości zapasów zgodnie z zapisami na dzień 31.12 ostatniego roku obrachunkowego w PLN.',
    false,
    false
  ),
  createData(
    'Środki trwałe (SUMA) w PLN',
    'fixed_assets_2022',
    'fixed_assets_2021',
    'fixed_assets_2020',
    'Prosimy o wprowadzenie wartości w punktach od a) do e) zgodnie z zapisami na dzień 31.12 ostatniego roku obrachunkowego w PLN.',
    true,
    false
  ),
  createData(
    '    a) grunty (w tym prawo użytkowania wieczystego gruntu) w PLN',
    'a_lands_2022',
    'a_lands_2021',
    'a_lands_2020',
    '',
    false,
    false
  ),
  createData(
    '    b) budynki, lokale, prawa do lokali i obiekty inżynierii lądowej i wodnej w PLN',
    'b_buildings_2022',
    'b_buildings_2021',
    'b_buildings_2020',
    '',
    false,
    false
  ),
  createData(
    '    c) urządzenia techniczne i maszyny w PLN',
    'c_technical_infrastructure_2022',
    'c_technical_infrastructure_2021',
    'c_technical_infrastructure_2020',
    '',
    false,
    false
  ),
  createData(
    '    d) środki transportu w PLN',
    'd_transport_2022',
    'd_transport_2021',
    'd_transport_2020',
    '',
    false,
    false
  ),
  createData(
    '    e) inne środki trwałe w PLN',
    'e_other_assets_2022',
    'e_other_assets_2021',
    'e_other_assets_2020',
    '',
    false,
    false
  ),
];
