import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import generatePDF, { Margin, Resolution } from 'react-to-pdf';
import { Button, Divider, Modal, ModalDialog, Typography } from '@mui/joy';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAuthInfo } from '@propelauth/react';

import Loading from '../../pages/dashboard/Loading';
import NetRevenuesFromSales from '../Charts/NetRevenuesFromSales/NetRevenuesFromSales';
import ProfitFromOperatingSales from '../Charts/ProfitFromOperatingSales/ProfitFromOperatingSales';
import { prefillSecondFormData } from '../CustomForm/ApiService';
import { SecondFormInputs } from '../CustomForm/FormsTypes';
import PdfSummary from '../CustomForm/PdfSummary';
import { getValidUrl } from '../Pricing/urlHelper';
import { getUsersConsultations, orderConsultation } from '../Pricing/useApi';

import logo from './GrowHub.svg';
import { RaportType } from './raportTypes';
import {
  countUsedRaportsGeneration,
  getDashboardData,
  getLeftGenerations,
  getRaportData,
} from './useApi';

export interface RaportGenerationResponse {
  created_at: string;
  updated_at: string;
  id: number;
  user_id: string;
  generated_reports_counter: number;
  generated_reports_limit: number;
}
export interface GetGenerationResponse {
  generated_reports: number;
  generated_reports_limit: number;
  reports_left: number;
}

export default function Dashboard() {
  const [open, setOpen] = useState<boolean>(false);

  /**
   * Raport
   */
  const [raport, setRaport] = useState<RaportType>();
  const [isRaportLoaded, setIsRaportLoaded] = useState<boolean>(false);
  /**
   * Left generations
   */
  const [leftGenerations, setLeftGenerations] =
    useState<RaportGenerationResponse>();
  const [canUserGenerateRaport, setCanUserGenerateRaport] =
    useState<boolean>(false);
  const [raportsLeft, setRaportsLeft] = useState<number>(0);
  const [leftGenerationsData, setLeftGenerationsData] =
    useState<GetGenerationResponse>();
  /**
   * Financial data for charts
   */
  const [financialData, setFinancialData] = useState<SecondFormInputs>();
  const [isFinancialDataLoaded, setIsFinancialDataLoaded] =
    useState<boolean>(false);
  const authInfo = useAuthInfo();
  const token = authInfo.accessToken;
  const dashboardRef = useRef<HTMLDivElement>(null);
  const summaryPrintRef = useRef<HTMLDivElement>(null);
  const innerDashboardRaportRef = useRef<HTMLDivElement>(null);
  const summaryPrintHeader = useRef<HTMLDivElement>(null);
  const downloadPdfRaportButtonRef = useRef<HTMLButtonElement>(null);
  const chartsRef = useRef<HTMLDivElement>(null);
  const ProfitFromOperatingSalesRef = useRef<HTMLDivElement>(null);
  const NetRevenuesFromSalesRef = useRef<HTMLDivElement>(null);
  const generateRaportRef = useRef<HTMLDivElement>(null);
  const talkWithOurAdvisorRef = useRef<HTMLDivElement>(null);
  const quotationRef = useRef<HTMLDivElement>(null);
  const quotationMainRef = useRef<HTMLDivElement>(null);
  const showTopLogoRef = useRef<HTMLDivElement>(null);
  const dashboardData = getDashboardData(token as string);
  async function fetchFinancialData() {
    try {
      const data = await prefillSecondFormData(token as string);
      setFinancialData(data);
      setIsFinancialDataLoaded(true);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchAndSetLeftGenerations() {
    try {
      const test = await getLeftGenerations(token as string);
      setLeftGenerationsData(test);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchRaportData() {
    try {
      const data = await getRaportData(token as string);
      setRaport(data);
      setIsRaportLoaded(true);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchRaportData();
    fetchFinancialData();
    fetchAndSetLeftGenerations();
  }, []);

  useEffect(() => {}, [isFinancialDataLoaded, isRaportLoaded, raport]);

  /**
   * Check if user can generate new raport
   */
  const canUserGenerateNewRaport = () => {
    if (leftGenerations === undefined) {
      return false;
    }
    if (
      leftGenerations?.generated_reports_counter >=
      leftGenerations?.generated_reports_limit
    ) {
      return false;
    } else {
      return true;
    }
  };
  /**
   * Not implemented yet
   */
  const notImplementedYet = () => {
    toast('Ta funkcja nie jest jeszcze dostępna.', {
      icon: '👏',
    });
  };
  /**
   * Generate PDF
   */
  const onPdf = () => {
    if (dashboardRef.current === null) {
      toast.error('Wykorzystałeś wszystkie raporty.');
      return;
    } else {
      countUsedRaportsGeneration(token as string);
      toast.success('Raport został wygenerowany.');
      summaryPrintRef.current?.classList.remove('hidden');
      downloadPdfRaportButtonRef.current?.classList.add('hidden');
      summaryPrintHeader.current?.classList.remove('hidden');
      talkWithOurAdvisorRef.current?.classList.add('hidden');
      quotationMainRef.current?.classList.remove('justify-center');
      quotationMainRef.current?.classList.remove('md:py-20');
      quotationMainRef.current?.classList.add('pb-8');
      showTopLogoRef.current?.classList.remove('hidden');
      printPdf();
      showTopLogoRef.current?.classList.add('hidden');
      downloadPdfRaportButtonRef.current?.classList.remove('hidden');
      talkWithOurAdvisorRef.current?.classList.remove('hidden');
      summaryPrintRef.current?.classList.add('hidden');
      summaryPrintHeader.current?.classList.add('hidden');
      quotationMainRef.current?.classList.add('justify-center');
      quotationMainRef.current?.classList.add('md:py-20');
      quotationMainRef.current?.classList.remove('pb-8');

      fetchAndSetLeftGenerations();
    }
  };
  const printPdf = () => {
    generatePDF(dashboardRef, {
      filename: 'Raport.pdf',
      resolution: Resolution.MEDIUM,
      page: {
        // margin is in MM, default is Margin.NONE = 0
        margin: Margin.SMALL,
      },
    });
  };

  if (!isRaportLoaded || !isFinancialDataLoaded) {
    return <Loading />;
  }

  /**
   * Financial number fisplay functions
   */

  function formatCurrency(number: number) {
    const formatter = new Intl.NumberFormat('pl-PL', {
      style: 'currency',
      currency: 'PLN',
      minimumFractionDigits: 2,
    });
    return formatter.format(number);
  }
  function formatFirstNumber(number: number) {
    const numberString: string = number.toFixed(2);
    const formatter = new Intl.NumberFormat('pl-PL', {
      minimumFractionDigits: 2,
    });
    return formatter.format(Number(numberString));
  }
  /**
   * Market value assets
   */

  const market_value_m1 =
    raport?.financial_raport != undefined
      ? raport?.financial_raport.simplified_valuation.m1 +
        raport?.financial_raport.asset_valuation
      : 0;
  const market_value_m2 =
    raport?.financial_raport != undefined
      ? raport?.financial_raport.simplified_valuation.m2 +
        raport?.financial_raport.asset_valuation
      : 0;

  /**
   * Consulatition usage logic
   */
  const useConsultation = async () => {
    const consultations = await getUsersConsultations(token as string);

    if (consultations.length >= 1) {
      // if I have more than 1 consultation (or exactly 1)
      for (let i = 0; i < consultations.length; i++) {
        // check if any of them is not used
        if (!consultations[i].used_consultation) {
          // if not used, use it
          await orderConsultation(
            token as string,
            consultations[i].consultation_type
          ); // order consultation
          const updatedConsultations = await getUsersConsultations(
            token as string
          ); // get updated consultations
          window.open(getValidUrl(updatedConsultations[i].url), '_blank'); // and open it in new tab
          return;
        }
      }
    } else {
      toast.error('Nie masz wykupionych konsultacji.');
    }
  };

  return (
    <div ref={dashboardRef}>
      <div ref={innerDashboardRaportRef}>
        <div className='hidden flex items-center' ref={summaryPrintHeader}>
          <div className='container px-8 border-[#85bb65] border-l-8 pt-4 pb-8 rounded-md'>
            <span className='font-bold text-2xl text-[#85bb65]'>
              Raport z Wyceny Szacunkowej
            </span>
          </div>
        </div>
        <main
          ref={quotationMainRef}
          className='grow max-w-content w-full md:flex-row mx-auto items-center px-4 sm:px-5 md:py-20 flex justify-center flex-col sm:flex-row'
        >
          <section
            className='mt-8 px-5 xl:px-0 flex w-full shrink-0 flex-col md:mr-8 md:mt-0 md:w-[50%] lg:mr-20'
            ref={quotationRef}
          >
            <h1 className='font-semibold text-gray80 sm:text-2xl'>
              Szacunkowa wartość rynkowa Twojego biznesu wynosi
            </h1>
            {isRaportLoaded ? (
              <div className='mb-4 mt-4 text-2xl font-bold text-[#85bb65] sm:text-4xl md:mt-6'>
                {formatFirstNumber(market_value_m1 as number)} -{' '}
                {formatFirstNumber(market_value_m2 as number)} zł
              </div>
            ) : (
              <div className='mb-4 mt-4 text-2xl font-bold text-[#85bb65] sm:text-4xl md:mt-6 max-w-sm animate-pulse'>
                Ładowanie danych
              </div>
            )}

            <span className='hidden mt-4 text-xs text-[#64717C] sm:text-base md:mt-2'>
              (wycena uproszczona)
            </span>

            <address className='mt-6 text-sm not-italic text-gray80 sm:text-base md:mt-4 '>
              <div className='mb-4 block'>
                <strong>Info:</strong> Szacowana wartość rynkowa Twojego biznesu
                to łączna wartość wyceny majątkowej oraz wskaźnikowej.
              </div>
              <strong>Wycena majątkowa</strong>:{' '}
              <span>
                {formatFirstNumber(
                  raport?.financial_raport.asset_valuation as number
                )}{' '}
                zł
              </span>
              <br />
              <strong>Wycena wskaźnikowa</strong>:{' '}
              <span>
                {formatFirstNumber(
                  raport?.financial_raport.simplified_valuation.m1 as number
                )}{' '}
                -{' '}
                {formatFirstNumber(
                  raport?.financial_raport.simplified_valuation.m2 as number
                )}{' '}
                zł
              </span>
            </address>

            <div className='mt-16' ref={generateRaportRef}>
              <button
                className={
                  leftGenerationsData != undefined &&
                  leftGenerationsData?.reports_left > 0
                    ? `button button--tertiary w-full mt-8 text-[#464B50] border shadow-md px-[2rem] pt-[1rem] pb-[1rem] rounded-md transition-all hover:bg-slate-50/90`
                    : `button button--tertiary w-full mt-8 text-slate-400 border shadow-md px-[2rem] pt-[1rem] pb-[1rem] rounded-md transition-all cursor-not-allowed bg-slate-200 hover:bg-slate-200/90`
                }
                onClick={() => {
                  if (leftGenerationsData === undefined) {
                    return;
                  }
                  if (leftGenerationsData?.reports_left > 0) {
                    onPdf();
                  } else {
                    toast.error('Wykorzystałeś wszystkie raporty.');
                  }
                }}
                ref={downloadPdfRaportButtonRef}
              >
                {leftGenerationsData != undefined &&
                leftGenerationsData?.reports_left > 0
                  ? 'Pobierz raport PDF.'
                  : 'Wykorzystałeś wszystkie raporty'}
              </button>
              {leftGenerationsData != undefined &&
              leftGenerationsData?.reports_left > 0 ? (
                <small className='mt-4 text-xs text-[#64717C]'>
                  Pozostały{' '}
                  <span className='font-bold'>
                    {leftGenerationsData?.reports_left} generacje
                  </span>{' '}
                  raportów
                </small>
              ) : (
                <small className='mt-4 text-xs text-[#64717C] flex flex-col'>
                  <span className='font-bold'>
                    Wykorzystałeś wszystkie generacje
                  </span>
                  <span className='cursor-pointer' onClick={notImplementedYet}>
                    Dokup nowe generacje
                  </span>
                </small>
              )}
            </div>
          </section>
          <section
            ref={showTopLogoRef}
            className='hidden md:mt-0 md:mb-0 md:w-[50%]'
          >
            <img src={logo} alt='Logo' />
          </section>
          <section
            className='mt-11 mb-20 flex w-full items-center justify-center md:mt-0 md:mb-0 md:w-[50%]'
            ref={talkWithOurAdvisorRef}
          >
            <div className='relative flex w-full flex-col rounded-2xl border bg-white px-4 py-8 md:w-[543px] md:px-8 md:py-16 border-gray10'>
              <div className='absolute top-0 left-0 h-2 w-full rounded-t-2xl bg-[#85bb65] opacity-80'></div>
              <h2 className='font-semibold text-gray80 sm:text-xl'>
                Porozmawiaj z naszym doradcą
              </h2>
              <p className='mt-4 text-xs text-[#64717C] sm:text-base'>
                Wykorzystaj konsultację z pakietu lub wykup aby omówić
                szczegółowo swoje wyniki i poznać dalsze możliwe kroki.
              </p>
              <div className='mt-6 w-full border-t border-gray10'></div>
              <h2 className='mt-6 font-semibold text-gray80 sm:text-xl'>
                Twoje konsultacje
              </h2>
              <p className='mt-2 text-xs sm:text-sm'>
                Konsultacja 45 minut z doradcą ds. wyceny i skalowania biznesu.
              </p>
              <div className='flex w-full items-center gap-4 my-4 mt-8'>
                <div
                  className='bg-[#85BB65] py-4 px-8 rounded-xl text-white font-bold cursor-pointer'
                  onClick={() => setOpen(true)}
                >
                  Umawiam
                </div>
                <Link to={'/book_consultation'}>
                  <div className='bg-[#DB1F48] py-4 px-8 rounded-xl text-white font-bold cursor-pointer'>
                    Kupuję
                  </div>
                </Link>
              </div>
              <h2 className='mt-6 font-semibold text-gray80 sm:text-xl'>
                Potrzebujesz więcej informacji?
              </h2>
              <p className='mt-2 text-xs sm:text-sm'>
                Napisz do nas na{' '}
                <a href='mailto:kontakt@growhub.com.pl'>
                  <span className='font-bold text-[#006CAB] cursor-pointer'>
                    kontakt@growhub.com.pl{' '}
                  </span>
                </a>
                Odpowiemy możliwie jak najszybciej.{' '}
              </p>
            </div>
          </section>
        </main>
        <div className='container xl:px-5'>
          <div className='flex gap-2 flex-col sm:flex-row' ref={chartsRef}>
            <div className='w-full' ref={ProfitFromOperatingSalesRef}>
              <Typography level='h4'>Zysk ze sprzedaży operacyjnej</Typography>
              {isFinancialDataLoaded && (
                <ProfitFromOperatingSales data={financialData} />
              )}
            </div>
            <div className='w-full' ref={NetRevenuesFromSalesRef}>
              <Typography level='h4'>Przychody netto ze sprzedaży</Typography>
              {isFinancialDataLoaded && (
                <NetRevenuesFromSales data={financialData} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <Modal open={open} onClose={() => setOpen(true)}>
        <ModalDialog variant='outlined' role='alertdialog'>
          <DialogTitle>Uwaga</DialogTitle>
          <Divider />
          <DialogContent>
            Hej wykorzystujesz właśnie konsultacje. Przeniesiemy cię zaraz na
            stronę calendly do umawiania spotkania. Jeśli z jakiegoś powodu
            przerwiesz ten proces, sprawdź swoją skrzynkę e-mail, znajdziesz tam
            link do wznowienia.
          </DialogContent>
          <DialogActions>
            <Button variant='solid' color='danger' onClick={useConsultation}>
              OK
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <div
        className='hidden flex flex-col items-center justify-center gap-12'
        ref={summaryPrintRef}
      >
        <div>
          <PdfSummary />
        </div>
        <div className='flex items-start gap-4 flex-col align-center py-8 px-8 bg-[#85bb65]/10 w-full'>
          <Typography level='h2'>NOTA PRAWNA</Typography>
          <div className='flex flex-col gap-2'>
            WAŻNE: Przed skorzystaniem z niniejszego kalkulatora wartości
            biznesu prosimy o zapoznanie się z poniższą notą prawną. Korzystając
            z tego narzędzia, akceptujesz warunki i ograniczenia określone
            poniżej.
            <span className='font-bold'>
              1. Cel Kalkulatora Wartości Biznesu:
            </span>
            Kalkulator wartości biznesu jest narzędziem przeznaczonym do
            szacowania wartości przedsiębiorstwa na podstawie dostarczonych
            danych. Jest to narzędzie informatywne i nie stanowi oferty ani
            rekomendacji finansowej. Wyniki uzyskane przy jego użyciu mają
            charakter przybliżony i nie mogą zastępować profesjonalnej analizy
            finansowej przeprowadzanej przez kompetentnego eksperta.
            <span className='font-bold'>2. Ograniczenia Kalkulatora:</span>
            Kalkulator wartości biznesu opiera się na dostępnych danych i
            założeniach, które mogą wpływać na wyniki. Ostateczna wartość firmy
            może być znacznie różna od wyniku uzyskanego za pomocą kalkulatora.
            Ograniczenia te obejmują: Brak uwzględnienia niestandardowych
            czynników wpływających na wartość firmy. Założenia dotyczące stóp
            zwrotu i innych parametrów, które mogą się zmieniać w czasie.
            Różnice w ocenie aktywów i pasywów przedsiębiorstwa. Brak
            uwzględnienia bieżącej kondycji rynku i koniunktury gospodarczej.
            <span className='font-bold'>
              3. Uzyskanie Profesjonalnej Pomocy:
            </span>
            Korzystając z kalkulatora wartości biznesu, rozumiesz, że to
            narzędzie nie zastępuje profesjonalnej porady finansowej ani usług
            eksperta ds. wyceny przedsiębiorstw. Zaleca się skonsultowanie się z
            licencjonowanym doradcą finansowym lub ekspertem ds. wyceny przed
            podjęciem jakichkolwiek decyzji biznesowych opartych na wynikach
            kalkulatora.
            <span className='font-bold'>4. Odpowiedzialność:</span>
            Autorzy oraz właściciel kalkulatora nie ponoszą odpowiedzialności za
            jakiekolwiek straty lub szkody wynikające z korzystania z tego
            narzędzia. Korzystasz z niego na własne ryzyko.
            <span className='font-bold'>5. Prawa Autorskie:</span>
            Kalkulator wartości biznesu oraz wszelkie związane z nim treści są
            chronione prawami autorskimi. Kopiowanie, modyfikacja lub
            rozpowszechnianie kalkulatora bez zgody właściciela jest zabronione.
            Korzystając z kalkulatora wartości biznesu, akceptujesz warunki i
            ograniczenia określone w powyższej notatce prawnej. Jeśli nie
            zgadzasz się z tymi warunkami, prosimy o niekorzystanie z tego
            narzędzia.
          </div>
        </div>
        <section className='flex justify-center'>
          <img src={logo} alt='Logo' className='w-[40%]' />
        </section>
      </div>
    </div>
  );
}
