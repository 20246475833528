import { Box, Card, Stack, Typography } from '@mui/joy';

import BasicAppPageWithSidebarTemplate from '../../templates/BasicAppPageWithSidebarTemplate/BasicAppPageWithSidebarTemplate';
import PricingCard from '../Pricing/PricingCard';

export default function BookConsultation() {
  const isFormSubmitted = true;
  const hasUserConsultation = false;
  return (
    <BasicAppPageWithSidebarTemplate>
      <Card sx={{ width: '100%' }}>
        {isFormSubmitted && (
          <Stack justifyContent='center' alignItems='center' spacing={5}>
            {!hasUserConsultation && (
              <Box>
                <PricingCard />
              </Box>
            )}
          </Stack>
        )}
        {!isFormSubmitted && (
          <Stack
            justifyContent='center'
            alignItems='center'
            spacing={5}
            sx={{ height: '30vh' }}
          >
            <Typography level='h1' textAlign='center'>
              Wypełnij formularz wyceny, aby umówić konsultacje z naszymi
              ekspertami
            </Typography>
          </Stack>
        )}
      </Card>
    </BasicAppPageWithSidebarTemplate>
  );
}
