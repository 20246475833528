import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Container, Stack, Table, Typography } from '@mui/joy';
import { useAuthInfo } from '@propelauth/react';

import HelpIcon from './HelpIcon/HelpIcon.tsx';
import {
  fetchFirstFormData,
  fetchSecondFormData,
  prefillSecondFormData,
  saveFirstFormData,
  saveSocondFormData,
} from './ApiService.ts';
import { firstFormOptions, secondFormOptions } from './forms.ts';
import { FirstFormInputs, SecondFormInputs } from './FormsTypes.ts';
import { firstFormSchema, secondFormSchema } from './ZodValidator.ts';

import './CustomForm.module.css'; // Import css modules stylesheet as styles

const rows = secondFormOptions;

export default function CustomSummary() {
  const [sumA, setSumA] = useState<number>(0);
  const [sumB, setSumB] = useState<number>(0);
  const [sumC, setSumC] = useState<number>(0);
  const authInfo = useAuthInfo();
  const accessToken = authInfo.accessToken;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    // reset,
    watch,
    formState,
    formState: { errors, isValidating }, // todo: add isDirty behavior
  } = useForm<FirstFormInputs>({
    mode: 'onChange',
    resolver: zodResolver(firstFormSchema),
    defaultValues: async () => fetchFirstFormData(accessToken as string),
  });
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    control: control2,
    // setValue: setValue2,
    formState: formState2,
    formState: { errors: errors2, isValidating: isValidating2 },
  } = useForm<SecondFormInputs>({
    mode: 'onChange',
    resolver: zodResolver(secondFormSchema),
    defaultValues: async () => prefillSecondFormData(accessToken as string),
  });

  const onSubmit: SubmitHandler<FirstFormInputs> = (data) =>
    saveFirstFormData(data, accessToken as string);

  const onSubmit2: SubmitHandler<SecondFormInputs> = async (data) => {
    const saveSecondFData = await saveSocondFormData(
      data,
      accessToken as string
    );
    // if data saved then navigate to summary page
    saveSecondFData.then(() => {
      navigate('/summary');
    });
  };
  const updateSubtotals = async () => {
    const data = await fetchSecondFormData(accessToken as string);
    for (let i = 0; i < data.length; i++) {
      if (data[i].year === 2022) {
        setSumA(data[i].fixed_assets);
      } else if (data[i].year === 2021) {
        setSumB(data[i].fixed_assets);
      } else if (data[i].year === 2020) {
        setSumC(data[i].fixed_assets);
      }
    }
  };

  const data = watch();
  const data2 = watch2();

  // 1st form submit on change
  useEffect(() => {
    if (formState.isValid && !isValidating) {
      const subscription = watch(() => handleSubmit(onSubmit)());

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [formState, data, isValidating]);

  // 2nd form submit on change
  useEffect(() => {
    if (formState2.isValid && !isValidating2) {
      const subscription = watch2(() => handleSubmit2(onSubmit2)());
      updateSubtotals();
      return () => subscription.unsubscribe();
    }
  }, [formState2, data2, isValidating2]);

  return (
    <Container
      maxWidth={'xl'}
      sx={{
        p: {
          xs: 0,
          sm: 5,
        },
      }}
    >
      <Box
        sx={{
          mt: '1rem',
          mb: '1rem',
        }}
      >
        <Typography level='h2' className='text-left'>
          Podsumowanie
        </Typography>
      </Box>
      <div className='max-w-[1920px] rounded-2xl bg-white px-5 py-8 text-center text-lg sm:mx-5 md:mx-auto xl:px-24 md:py-12 relative overflow-hidden border-b border-gray10 shadow-md'>
        <div className='absolute left-0 top-0 h-4 w-full bg-[#85bb65] opacity-50'></div>
        <div className='mt-[1rem] mb-[1rem]'>
          <Typography level='h2' className='text-left'>
            Informacje o działalności
          </Typography>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              justifyContent='center'
              alignItems='center'
              spacing={5}
              sx={{
                p: {
                  xs: 0,
                  sm: 1,
                },
              }}
            >
              <Box
                sx={{
                  textAlign: 'left',
                }}
              >
                <Typography>
                  Dane, które wprowadzasz w tej zakładce, mają bezpośredni wpływ
                  na wycenę rynkową Twojej firmy i nie będziemy ich nikomu
                  udostępniać. Szegółowe informacje wyjaśniajace każdą pozycję
                  znajdziesz w naszym przewodniku.
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'grid',
                    gridColumns: '1fr 1fr',
                    gap: 2,
                    width: '100%',
                  }}
                >
                  {/********************************************************************************************************************************** */}
                  {/** FIRST FORM */}
                  {firstFormOptions.map((option, index) => (
                    <div
                      className='grid grid-cols-1 items-center sm:grid-cols-2'
                      key={index}
                    >
                      <div className='flex items-center text-sm font-semibold text-gray80 sm:pb-2'>
                        <Typography>
                          {option.title}
                          <HelpIcon message={option.hint} />
                        </Typography>
                        <ErrorMessage
                          errors={errors}
                          name={option.key as keyof FirstFormInputs}
                          render={({ message }) => (
                            <Box
                              sx={{
                                color: 'red',
                              }}
                            >
                              {message}
                            </Box>
                          )}
                        />
                      </div>
                      <div className='border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white shadow border-gray10 rounded'>
                        {option.key === 'sector' ? (
                          <>
                            {Array.from({ length: 3 }, (_, index) => (
                              <select
                                key={index}
                                {...register(
                                  `${option.key}${
                                    index !== undefined ? `_${index + 1}` : ''
                                  }` as keyof FirstFormInputs
                                )}
                                placeholder={option.placeholder}
                                className={`text-black h-full grow appearance-none bg-transparent border-r px-2 pr-2 xl:px-3.5 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-base ${
                                  index === 0 ? '' : 'hidden'
                                }`}
                                disabled
                              >
                                {option.answers.map((answer) => (
                                  <option
                                    key={answer.value}
                                    value={answer.value}
                                  >
                                    {answer.label}
                                  </option>
                                ))}
                              </select>
                            ))}
                          </>
                        ) : (
                          <>
                            <select
                              {...register(
                                `${option.key}` as keyof FirstFormInputs,
                                {
                                  setValueAs: (value) => {
                                    if (value === 'true') {
                                      return true;
                                    } else if (value === 'false') {
                                      return false;
                                    } else {
                                      return value;
                                    }
                                  },
                                }
                              )}
                              placeholder={option.placeholder}
                              className='text-black h-full grow appearance-none bg-transparent px-1 xl:px-3.5 xl:pr-8  leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-base'
                              disabled
                            >
                              {option.answers.map((answer) => (
                                <option key={answer.value} value={answer.value}>
                                  {answer.label}
                                </option>
                              ))}
                            </select>
                            <div className='text-slate-100 hidden'>
                              <EditIcon
                                sx={{
                                  color: 'rgb(226 232 240)',
                                  cursor: 'pointer',
                                  mr: '.78rem',
                                  '&:hover': {
                                    color: 'rgb(100 116 139);',
                                  },
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                  {/** END OF FIRST FORM */}
                  {/********************************************************************************************************************************** */}
                </Box>
              </Box>
            </Stack>
          </form>
          <Box
            sx={{
              mt: '1rem',
              mb: '1rem',
            }}
          >
            <Typography level='h2' className='text-left'>
              Dane finansowe
            </Typography>
          </Box>
          <Box>
            <form onSubmit={handleSubmit2(onSubmit2)}>
              <Table>
                <thead>
                  <tr className='text-base font-bold'>
                    <th
                      style={{
                        width: '60%',
                      }}
                    >
                      Rok{' '}
                      <HelpIcon
                        message={`Prosimy o wprowadzenie danych księgowych z RZiS lub innego dokumentu księgowego za dany rok obrachunkowy.`}
                      />
                    </th>
                    <th>2023</th>
                    <th>2022</th>
                    <th>2021</th>
                  </tr>
                </thead>
                <tbody>
                  {/********************************************************************************************************************************** */}
                  {/** SECOND FORM  */}
                  {rows.map((row) => {
                    if (row.suma) {
                      return (
                        <tr
                          key={row.name}
                          style={{
                            background: 'white',
                          }}
                        >
                          <td>
                            <div className='text-left font-bold text-base my-5'>
                              {row.name}{' '}
                              {row.hint ? (
                                <HelpIcon message={row.hint} />
                              ) : (
                                <></>
                              )}
                            </div>
                            <ErrorMessage
                              errors={errors2}
                              name={row.form_id_1 as keyof SecondFormInputs}
                              render={({ message }) => (
                                <Box
                                  sx={{
                                    color: 'red',
                                  }}
                                >
                                  2023 {message}
                                </Box>
                              )}
                            />
                            <ErrorMessage
                              errors={errors2}
                              name={row.form_id_2 as keyof SecondFormInputs}
                              render={({ message }) => (
                                <Box
                                  sx={{
                                    color: 'red',
                                  }}
                                >
                                  2022 {message}
                                </Box>
                              )}
                            />
                            <ErrorMessage
                              errors={errors2}
                              name={row.form_id_3 as keyof SecondFormInputs}
                              render={({ message }) => (
                                <Box
                                  sx={{
                                    color: 'red',
                                  }}
                                >
                                  2021 {message}
                                </Box>
                              )}
                            />
                          </td>

                          <td colSpan={3}>
                            <input
                              placeholder='0'
                              type='number'
                              disabled
                              defaultValue={0}
                              min='0'
                              {...register2(
                                `${row.form_id_1}` as keyof SecondFormInputs,
                                {
                                  valueAsNumber: true,
                                }
                              )}
                              className='hidden text-black h-full grow appearance-none bg-transparent px-1 xl:px-3.5 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'
                            />
                            <div className='flex items-center text-slate-400 text-left h-full grow appearance-none bg-transparent px-1 sm:px-3.5  leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'>
                              <NumericFormat
                                displayType='text'
                                value={sumA}
                                thousandSeparator=' '
                              />
                            </div>
                          </td>
                          <td className='hidden'>
                            <input
                              placeholder='0'
                              type='number'
                              defaultValue={0}
                              disabled
                              min='0'
                              {...register2(
                                `${row.form_id_2}` as keyof SecondFormInputs,
                                {
                                  valueAsNumber: true,
                                }
                              )}
                              className='hidden text-black h-full grow appearance-none bg-transparent px-1 xl:px-3.5  leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'
                            />
                            <div className='hidden flex items-center text-black text-left h-full grow appearance-none bg-transparent px-1 xl:px-3.5  leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'>
                              <NumericFormat
                                displayType='text'
                                value={sumB}
                                thousandSeparator=' '
                              />
                            </div>
                          </td>
                          <td className='hidden'>
                            <input
                              placeholder='0'
                              type='number'
                              defaultValue={0}
                              disabled
                              min='0'
                              {...register2(
                                `${row.form_id_3}` as keyof SecondFormInputs,
                                {
                                  valueAsNumber: true,
                                }
                              )}
                              className='hidden text-black h-full grow appearance-none bg-transparent px-1 xl:px-3.5 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'
                            />
                            <div className='hidden flex items-center text-black text-left h-full grow appearance-none bg-transparent px-1 xl:px-3.5  leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'>
                              <NumericFormat
                                displayType='text'
                                value={sumC}
                                thousandSeparator=' '
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    } else {
                      if (row.show === false) {
                        /**TODO */
                        return (
                          <tr key={row.name}>
                            <td>
                              <div className='text-left font-semibold'>
                                {row.name}{' '}
                                {row.hint ? (
                                  <HelpIcon message={row.hint} />
                                ) : (
                                  <></>
                                )}
                              </div>
                            </td>

                            <td colSpan={3}>
                              <div className='border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white shadow border-gray10 rounded'>
                                <Controller
                                  control={control2}
                                  name={
                                    `${row.form_id_1}` as keyof SecondFormInputs
                                  }
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <NumericFormat
                                      defaultValue={0}
                                      value={value}
                                      allowNegative={false}
                                      inputMode='numeric'
                                      onValueChange={(values, sourceInfo) => {
                                        onChange(values.floatValue);
                                      }}
                                      onBlur={onBlur}
                                      thousandSeparator=' '
                                      className='text-slate-400 h-full grow appearance-none bg-transparent px-1 xl:px-3.5 xl:pr-8 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'
                                      disabled
                                    />
                                  )}
                                />
                              </div>
                            </td>
                            <td className='hidden'>
                              <div className='hidden border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white shadow border-gray10 rounded'>
                                <Controller
                                  control={control2}
                                  name={
                                    `${row.form_id_2}` as keyof SecondFormInputs
                                  }
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <NumericFormat
                                      defaultValue={0}
                                      value={value}
                                      allowNegative={false}
                                      inputMode='numeric'
                                      onValueChange={(values, sourceInfo) => {
                                        onChange(values.floatValue);
                                      }}
                                      onBlur={onBlur}
                                      thousandSeparator=' '
                                      className='text-slate-400 h-full grow appearance-none bg-transparent px-1 xl:px-3.5 xl:pr-8 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'
                                    />
                                  )}
                                />
                              </div>
                            </td>
                            <td className='hidden'>
                              <div className='hidden border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white shadow border-gray10 rounded'>
                                <Controller
                                  control={control2}
                                  name={
                                    `${row.form_id_3}` as keyof SecondFormInputs
                                  }
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <NumericFormat
                                      defaultValue={0}
                                      value={value}
                                      allowNegative={false}
                                      inputMode='numeric'
                                      onValueChange={(values, sourceInfo) => {
                                        onChange(values.floatValue);
                                      }}
                                      onBlur={onBlur}
                                      thousandSeparator=' '
                                      className='text-slate-400 h-full grow appearance-none bg-transparent px-1 xl:px-3.5 xl:pr-8 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'
                                      disabled
                                    />
                                  )}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr key={row.name}>
                            <td>
                              <div className='text-left font-semibold'>
                                {row.name}{' '}
                                {row.hint ? (
                                  <HelpIcon message={row.hint} />
                                ) : (
                                  <></>
                                )}
                              </div>
                            </td>

                            <td>
                              <div className='border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white shadow border-gray10 rounded'>
                                <Controller
                                  control={control2}
                                  name={
                                    `${row.form_id_1}` as keyof SecondFormInputs
                                  }
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <NumericFormat
                                      defaultValue={0}
                                      value={value}
                                      allowNegative={false}
                                      inputMode='numeric'
                                      onValueChange={(values, sourceInfo) => {
                                        onChange(values.floatValue);
                                      }}
                                      onBlur={onBlur}
                                      thousandSeparator=' '
                                      className='text-slate-400 h-full grow appearance-none bg-transparent px-1 xl:px-3.5 xl:pr-8 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'
                                      disabled
                                    />
                                  )}
                                />
                              </div>
                            </td>
                            <td>
                              <div className='border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white shadow border-gray10 rounded'>
                                <Controller
                                  control={control2}
                                  name={
                                    `${row.form_id_2}` as keyof SecondFormInputs
                                  }
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <NumericFormat
                                      defaultValue={0}
                                      value={value}
                                      allowNegative={false}
                                      inputMode='numeric'
                                      onValueChange={(values, sourceInfo) => {
                                        onChange(values.floatValue);
                                      }}
                                      onBlur={onBlur}
                                      thousandSeparator=' '
                                      className='text-slate-400 h-full grow appearance-none bg-transparent px-1 xl:px-3.5 xl:pr-8 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'
                                      disabled
                                    />
                                  )}
                                />
                              </div>
                            </td>
                            <td>
                              <div className='border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white shadow border-gray10 rounded'>
                                <Controller
                                  control={control2}
                                  name={
                                    `${row.form_id_3}` as keyof SecondFormInputs
                                  }
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <NumericFormat
                                      defaultValue={0}
                                      value={value}
                                      allowNegative={false}
                                      inputMode='numeric'
                                      onValueChange={(values, sourceInfo) => {
                                        onChange(values.floatValue);
                                      }}
                                      onBlur={onBlur}
                                      thousandSeparator=' '
                                      className='text-slate-400 h-full grow appearance-none bg-transparent px-1 xl:px-3.5 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'
                                      disabled
                                    />
                                  )}
                                />
                                <div className='text-slate-100 hidden'>
                                  <EditIcon
                                    sx={{
                                      color: 'rgb(226 232 240)',
                                      cursor: 'pointer',
                                      mr: '-3.5rem',
                                      '&:hover': {
                                        color: 'rgb(100 116 139);',
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    }
                  })}
                  {/********************************************************************************************************************************** */}
                </tbody>
              </Table>
              <div className='flex gap-2'>
                <Button
                  slotProps={{
                    root: {
                      type: 'submit',
                      component: 'button',
                    },
                  }}
                  sx={{
                    width: '100%',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    textDecoration: 'none',
                    backgroundColor: '#85bb65',
                    marginTop: '1rem',
                    '&:hover': {
                      backgroundColor: '#85bb65 !important',
                      bgcolor: '#85bb65 !important',
                      background: '#85bb65 !important',
                    },
                    '&:active': {
                      boxShadow: 'none !important',
                      backgroundColor: '#85bb65 !important',
                    },
                  }}
                  className='cursor-pointer text-white button flex items-center transition-all justify-center disabled:opacity-50 disabled:shadow-none disabled:cursor-not-allowed mt-10 w-full rounded-lg font-semibold md:mt-8 bg-[#34A853]'
                  onClick={() => {
                    toast.success('Generuję raport');
                    toast.success('Generuję wykresy');
                    toast.success('Wykonuję kalkulację wartości biznesu');
                    const redirect = () => {
                      navigate('/dashboard');
                    };
                    setTimeout(redirect, 3000);
                  }}
                >
                  Wyceniam biznes!
                </Button>
              </div>
            </form>
          </Box>
        </div>
      </div>
    </Container>
  );
}
