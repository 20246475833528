import React from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { CssVarsProvider } from '@mui/joy/styles';
import { RequiredAuthProvider } from '@propelauth/react';

import Loading from './pages/dashboard/Loading';
import Redirect from './pages/dashboard/Redirect.tsx';
import { fluentTheme } from './themes/fluentTheme.ts';
import { AUTH_URL } from './utils/app/const';
import App from './App.tsx';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RequiredAuthProvider
      authUrl={AUTH_URL}
      displayWhileLoading={<Loading />}
      displayIfLoggedOut={<Redirect />}
    >
      <CssVarsProvider theme={fluentTheme}>
        <Toaster />
        <App />
      </CssVarsProvider>
    </RequiredAuthProvider>
  </React.StrictMode>
);
