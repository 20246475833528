import { createContext } from 'react';

interface HeaderContextType {
  pickedForm: string;
  setPickedForm: () => void;
}

export const HeaderContext = createContext({
  pickedForm: '',
  setPickedForm: (arg: string) => {},
});
