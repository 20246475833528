import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { HeaderContext } from './store/HeaderContext';
import { Router } from './router';

import './App.css';

function App() {
  const [pickedForm, setPickedForm] = useState<string>('');

  return (
    <HeaderContext.Provider value={{ pickedForm, setPickedForm }}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </HeaderContext.Provider>
  );
}

export default App;
