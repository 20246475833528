import * as z from 'zod';

export const firstFormSchema = z.object({
  form_of_business: z.string().min(1),
  sector_1: z.string().min(1),
  sector_2: z.string(),
  sector_3: z.string(),
  employment_size: z.string().min(1),
  headquarter: z.string().min(1),
  departments: z.boolean(),
  eu_departments: z.boolean(),
  intercontinental_departments: z.boolean(),
  market: z.string().min(1),
  capital_debit: z.boolean(),
  accounting: z.string().min(1),
  licensed_activity: z.boolean(),
  company_import: z.boolean(),
  ecommerce: z.boolean(),
});

export const secondFormSchema = z.object({
  net_revenue_2020: z.number(),
  net_revenue_2021: z.number(),
  net_revenue_2022: z.number(),
  operating_profit_2020: z.number(),
  operating_profit_2021: z.number(),
  operating_profit_2022: z.number(),
  depreciation_2020: z.number(),
  depreciation_2021: z.number(),
  depreciation_2022: z.number(),
  total_cash_2020: z.number(),
  total_cash_2021: z.number(),
  total_cash_2022: z.number(),
  commitments_2020: z.number(),
  commitments_2021: z.number(),
  commitments_2022: z.number(),
  three_month_salary_budget_2020: z.number(),
  three_month_salary_budget_2021: z.number(),
  three_month_salary_budget_2022: z.number(),
  receivables_not_overdue_2020: z.number(),
  receivables_not_overdue_2021: z.number(),
  receivables_not_overdue_2022: z.number(),
  supplies_2020: z.number(),
  supplies_2021: z.number(),
  supplies_2022: z.number(),
  fixed_assets_2020: z.number().optional(),
  fixed_assets_2021: z.number().optional(),
  fixed_assets_2022: z.number().optional(),
  a_lands_2020: z.number(),
  a_lands_2021: z.number(),
  a_lands_2022: z.number(),
  b_buildings_2020: z.number(),
  b_buildings_2021: z.number(),
  b_buildings_2022: z.number(),
  c_technical_infrastructure_2020: z.number(),
  c_technical_infrastructure_2021: z.number(),
  c_technical_infrastructure_2022: z.number(),
  d_transport_2020: z.number(),
  d_transport_2021: z.number(),
  d_transport_2022: z.number(),
  e_other_assets_2020: z.number(),
  e_other_assets_2021: z.number(),
  e_other_assets_2022: z.number(),
});
