import { useContext, useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Stack,
  Tab,
  Table,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from '@mui/joy';
import { useAuthInfo } from '@propelauth/react';

import { HeaderContext } from '../../store/HeaderContext.ts';
import GuidebookModal from '../GuidebookModal/GuidebookModal.tsx';

import HelpIcon from './HelpIcon/HelpIcon.tsx';
import {
  fetchFirstFormData,
  fetchSecondFormData,
  prefillSecondFormData,
  saveFirstFormData,
  saveSocondFormData,
} from './ApiService.ts';
import { firstFormOptions, secondFormOptions } from './forms.ts';
import { FirstFormInputs, SecondFormInputs } from './FormsTypes';
import { firstFormSchema, secondFormSchema } from './ZodValidator.ts';

import './CustomForm.module.css'; // Import css modules stylesheet as styles

const rows = secondFormOptions;

type Props = {
  currentTab: number;
};

export default function CustomForm({ currentTab }: Props) {
  const [sumA, setSumA] = useState<number>(0);
  const [sumB, setSumB] = useState<number>(0);
  const [sumC, setSumC] = useState<number>(0);
  const [isFirstTimeUser, setIsFirstTimeUser] = useState<boolean>(true);
  const financialButtonRef = useRef<HTMLButtonElement | null>(null);
  const businessButtonRef = useRef<HTMLButtonElement | null>(null);
  const { pickedForm, setPickedForm } = useContext(HeaderContext);
  const authInfo = useAuthInfo();
  const accessToken = authInfo.accessToken;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState,
    formState: { errors, isValid, isValidating }, // todo: add isDirty behavior
  } = useForm<FirstFormInputs>({
    mode: 'onChange',
    resolver: zodResolver(firstFormSchema),
    defaultValues: async () => fetchFirstFormData(accessToken as string),
  });
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    control: control2,
    formState: formState2,
    formState: {
      errors: errors2,
      isValid: isValid2,
      isValidating: isValidating2,
    },
  } = useForm<SecondFormInputs>({
    mode: 'onTouched',
    resolver: zodResolver(secondFormSchema),
    defaultValues: async () => prefillSecondFormData(accessToken as string),
  });

  const onSubmit: SubmitHandler<FirstFormInputs> = (data) => {
    saveFirstFormData(data, accessToken as string);
  };

  const navigateToFinancialData = () => {
    handleSubmit(onSubmit)();
    navigate('/financial_data');
  };
  const navigateToSummary = () => {
    toast.success('Za chwilę zostaniesz przekierowany do podsumowania!');
    handleSubmit2(onSubmit2)();
    navigate('/summary');
  };
  const onSubmit2: SubmitHandler<SecondFormInputs> = async (data) => {
    const saveSecondFData = await saveSocondFormData(
      data,
      accessToken as string
    );
    // if data saved then navigate to summary page
    saveSecondFData.then(() => {
      navigate('/summary');
    });
  };
  // check if first time user
  const checkIfUserIsFirstTimeUser = async () => {
    const data = await fetchFirstFormData(accessToken as string);
    console.log(data);
    if (data !== undefined && data !== null) {
      setIsFirstTimeUser(false);
    }
  };
  const updateSubtotals = async () => {
    const data = await fetchSecondFormData(accessToken as string);
    for (let i = 0; i < data.length; i++) {
      if (data[i].year === 2022) {
        setSumA(data[i].fixed_assets);
      } else if (data[i].year === 2021) {
        setSumB(data[i].fixed_assets);
      } else if (data[i].year === 2020) {
        setSumC(data[i].fixed_assets);
      }
    }
  };
  // execute only when form is valid
  // on every change of formState, data is updated
  const data = watch();
  const data2 = watch2();

  // 1st form submit on change
  useEffect(() => {
    if (formState.isValid && !isValidating) {
      const subscription = watch(() => handleSubmit(onSubmit)());

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [formState, data, isValidating]);

  // 2nd form submit on change
  useEffect(() => {
    if (formState2.isValid && !isValidating2) {
      const subscription = watch2(() => handleSubmit2(onSubmit2)());
      updateSubtotals();
      return () => subscription.unsubscribe();
    }
  }, [formState2, data2, isValidating2]);
  useEffect(() => {
    if (pickedForm === 'financial_form') {
      setPickedForm('');
      if (financialButtonRef.current) {
        financialButtonRef.current.click();
      }
    } else if (pickedForm === 'business_form') {
      setPickedForm('');
      if (businessButtonRef.current) {
        businessButtonRef.current.click();
      }
    }
  }, [businessButtonRef, financialButtonRef, pickedForm, setPickedForm]);
  useEffect(() => {
    financialButtonRef.current?.addEventListener('click', () => {
      navigate('/financial_data');
    });
    businessButtonRef.current?.addEventListener('click', () => {
      navigate('/');
    });
  }, []);

  useEffect(() => {}, [currentTab]);
  useEffect(() => {
    checkIfUserIsFirstTimeUser();
  }, []);
  return (
    <Container
      maxWidth={'xl'}
      sx={{
        p: {
          xs: 0,
          sm: 5,
        },
      }}
    >
      <Tabs
        defaultValue='1'
        value={currentTab}
        className='max-w-[1920px] rounded-2xl bg-white px-1 py-2 md:px-5 md:py-8 text-lg sm:mx-5 md:mx-auto xl:px-24 lg:py-12 relative overflow-hidden border-b border-gray10 shadow-md'
      >
        <div className='absolute left-0 top-0 h-4 w-full bg-[#85bb65] opacity-50'></div>
        <div className='flex'>
          <ButtonGroup
            buttonFlex={1}
            aria-label='flex button group'
            variant='soft'
            size='lg'
            sx={{
              p: {
                xs: '0.5rem',
                sm: '1rem',
              },
              width: '100%',
              maxWidth: '100%',
              overflow: 'auto',
              resize: 'horizontal',
              '&:hover': {
                backgroundColor: 'inherit',
              },
            }}
            className='hidden'
          >
            <TabList>
              <Tab
                ref={businessButtonRef}
                value={1}
                sx={{
                  p: {
                    xs: '0.5rem',
                    sm: '1rem',
                  },
                }}
              >
                Informacje o działalności
              </Tab>
              <Tab
                ref={financialButtonRef}
                value={2}
                sx={{
                  p: {
                    xs: '0.5rem',
                    sm: '1rem',
                  },
                }}
                disabled={!isValid}
              >
                Dane finansowe
              </Tab>
            </TabList>
          </ButtonGroup>
          <GuidebookModal />
        </div>
        <TabPanel value={1} sx={{}}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              justifyContent='center'
              alignItems='center'
              spacing={5}
              sx={{
                p: {
                  xs: 0,
                  sm: 1,
                },
              }}
            >
              <Box>
                <Typography>
                  Dane, które wprowadzasz w tej zakładce, mają bezpośredni wpływ
                  na wycenę rynkową Twojej firmy i nie będziemy ich nikomu
                  udostępniać. Szegółowe informacje wyjaśniajace każdą pozycję
                  znajdziesz w naszym przewodniku.
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'grid',
                    gridColumns: '1fr 1fr',
                    gap: 2,
                    width: '100%',
                  }}
                >
                  {/********************************************************************************************************************************** */}
                  {/** FIRST FORM */}
                  {firstFormOptions.map((option, index) => (
                    <div
                      className='grid grid-cols-1 items-center sm:grid-cols-2'
                      key={index}
                    >
                      <div className='flex items-center text-sm text-left font-semibold text-gray80 sm:pb-2'>
                        <Typography sx={{ textAlign: 'left' }}>
                          {option.title}
                          <HelpIcon message={option.hint} />
                        </Typography>
                        <ErrorMessage
                          errors={errors}
                          name={option.key as keyof FirstFormInputs}
                          render={({ message }) => (
                            <Box
                              sx={{
                                color: 'red',
                              }}
                              className='hidden'
                            >
                              {message}
                            </Box>
                          )}
                        />
                      </div>
                      <div className='border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white shadow border-gray10 rounded'>
                        {option.key === 'sector' ? (
                          <>
                            {Array.from({ length: 3 }, (_, index) => (
                              <select
                                key={index}
                                {...register(
                                  `${option.key}${
                                    index !== undefined ? `_${index + 1}` : ''
                                  }` as keyof FirstFormInputs,
                                  {
                                    required: index === 0 ? true : false,
                                  }
                                )}
                                placeholder={option.placeholder}
                                defaultValue={''}
                                className={`text-black h-full grow appearance-none bg-transparent border-r px-2 pr-2 md:px-3.5 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap ${
                                  index === 0 ? '' : 'hidden'
                                }`}
                              >
                                {index === 0 && isFirstTimeUser ? (
                                  <option value='' disabled>
                                    Wybierz jedno z poniższych
                                  </option>
                                ) : (
                                  <></>
                                )}

                                {option.answers.map((answer) => (
                                  <option
                                    key={answer.value}
                                    value={answer.value}
                                  >
                                    {answer.label}
                                  </option>
                                ))}
                              </select>
                            ))}
                          </>
                        ) : (
                          <select
                            {...register(
                              `${option.key}` as keyof FirstFormInputs,
                              {
                                setValueAs: (value) => {
                                  if (value === 'true') {
                                    return true;
                                  } else if (value === 'false') {
                                    return false;
                                  } else {
                                    return value;
                                  }
                                },
                                required: true,
                              }
                            )}
                            placeholder={option.placeholder}
                            defaultValue={''}
                            className='text-black h-full grow appearance-none bg-transparent px-2 pr-2 md:px-3.5 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-base'
                          >
                            {isFirstTimeUser ? (
                              <option value='' disabled>
                                Wybierz jedno z poniższych
                              </option>
                            ) : (
                              <></>
                            )}

                            {option.answers.map((answer) => (
                              <option key={answer.value} value={answer.value}>
                                {answer.label}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                  ))}
                  {/** END OF FIRST FORM */}
                  {/********************************************************************************************************************************** */}
                </Box>
              </Box>
            </Stack>
            <Stack
              flexDirection={{
                xs: 'column-reverse',
                sm: 'row',
              }}
              justifyContent='space-between'
              gap={'20px'}
              alignItems={'center'}
              sx={{
                marginTop: {
                  xs: '1rem',
                  sm: '0',
                },
              }}
            >
              <Box
                sx={{
                  width: '100%',
                }}
              >
                {' '}
                <TabList
                  sx={{
                    width: '100%',
                  }}
                >
                  <Button
                    slotProps={{
                      root: {
                        type: 'submit',
                        component: 'button',
                      },
                    }}
                    sx={{
                      width: '100%',
                      padding: '1rem',
                      borderRadius: '0.5rem',
                      textDecoration: 'none',
                      backgroundColor: '#85bb65',
                      marginTop: '1rem',
                      '&:hover': {
                        backgroundColor: '#85bb65 !important',
                        bgcolor: '#85bb65 !important',
                        background: '#85bb65 !important',
                      },
                      '&:active': {
                        boxShadow: 'none !important',
                        backgroundColor: '#85bb65 !important',
                      },
                    }}
                    className='cursor-pointer text-white button flex items-center transition-all justify-center disabled:opacity-50 disabled:shadow-none disabled:cursor-not-allowed mt-10 w-full rounded-lg font-semibold md:mt-8 bg-[#34A853]'
                    onClick={navigateToFinancialData}
                    disabled={!isValid}
                  >
                    Potwierdzam poprawność danych
                  </Button>
                </TabList>
              </Box>
            </Stack>
          </form>
        </TabPanel>
        <TabPanel
          value={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <Box>
            Dane jakie nam udostępniasz są w pełni anonimowe i nie są przypisane
            na żadnych nośnikach do Twojej firmy. Ponadto na tym etapie, nie
            prosimy Cię o żadne informacje, których nie można odnaleźć w
            sprawozdaniach finansowych, danych teleadresowych oraz ogólnie w
            sieci.
          </Box>
          <Box>
            <form onSubmit={handleSubmit2(onSubmit2)}>
              <Table hoverRow>
                <thead>
                  <tr className='font-bold text-base'>
                    <th className='w-1/2 2xl:w-[60%]'>
                      Rok{' '}
                      <HelpIcon
                        message={`Prosimy o wprowadzenie danych księgowych z RZiS lub innego dokumentu księgowego za dany rok obrachunkowy.`}
                      />
                    </th>
                    <th>2023</th>
                    <th>2022</th>
                    <th>2021</th>
                  </tr>
                </thead>
                <tbody>
                  {/********************************************************************************************************************************** */}
                  {/** SECOND FORM  */}
                  {rows.map((row) => {
                    if (row.suma) {
                      return (
                        <tr
                          key={row.name}
                          style={{
                            background: 'white',
                          }}
                        >
                          <td>
                            <div className='text-left font-bold text-base my-5'>
                              {row.name}
                              {row.hint ? (
                                <HelpIcon message={row.hint} />
                              ) : (
                                <></>
                              )}
                            </div>
                            <ErrorMessage
                              errors={errors2}
                              name={row.form_id_1 as keyof SecondFormInputs}
                              render={({ message }) => (
                                <Box
                                  sx={{
                                    color: 'red',
                                  }}
                                  className='hidden'
                                >
                                  2023 {message}
                                </Box>
                              )}
                            />
                            <ErrorMessage
                              errors={errors2}
                              name={row.form_id_2 as keyof SecondFormInputs}
                              render={({ message }) => (
                                <Box
                                  sx={{
                                    color: 'red',
                                  }}
                                  className='hidden'
                                >
                                  2022 {message}
                                </Box>
                              )}
                            />
                            <ErrorMessage
                              errors={errors2}
                              name={row.form_id_3 as keyof SecondFormInputs}
                              render={({ message }) => (
                                <Box
                                  sx={{
                                    color: 'red',
                                  }}
                                  className='hidden'
                                >
                                  2021 {message}
                                </Box>
                              )}
                            />
                          </td>

                          <td colSpan={3}>
                            <input
                              placeholder='0'
                              type='number'
                              disabled
                              defaultValue={0}
                              min='0'
                              {...register2(
                                `${row.form_id_1}` as keyof SecondFormInputs,
                                {
                                  valueAsNumber: true,
                                }
                              )}
                              className='hidden text-black h-full grow appearance-none bg-transparent px-1 pr-1 md:px-3.5 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs  lg:text-base text-center'
                            />
                            <div className='flex items-center text-black text-left h-full grow appearance-none bg-transparent px-1 pr-1 md:px-3.5 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs lg:text-base'>
                              <NumericFormat
                                displayType='text'
                                value={sumA}
                                thousandSeparator=' '
                              />
                            </div>
                          </td>
                          <td className='hidden'>
                            <input
                              placeholder='0'
                              type='number'
                              defaultValue={0}
                              disabled
                              min='0'
                              {...register2(
                                `${row.form_id_2}` as keyof SecondFormInputs,
                                {
                                  valueAsNumber: true,
                                }
                              )}
                              className='hidden text-black h-full grow appearance-none bg-transparent px-1 pr-1 md:px-3.5 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs  lg:text-base text-center'
                            />
                            <div className='flex items-center text-black h-full grow appearance-none bg-transparent px-1 pr-1 md:px-3.5 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs  lg:text-base text-center'>
                              <NumericFormat
                                displayType='text'
                                value={sumB}
                                thousandSeparator=' '
                              />
                            </div>
                          </td>
                          <td className='hidden'>
                            <input
                              placeholder='0'
                              type='number'
                              defaultValue={0}
                              disabled
                              min='0'
                              {...register2(
                                `${row.form_id_3}` as keyof SecondFormInputs,
                                {
                                  valueAsNumber: true,
                                }
                              )}
                              className='hidden text-black h-full grow appearance-none bg-transparent px-1 pr-1 md:px-3.5 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs lg:text-base'
                            />
                            <div className='flex items-center text-black h-full grow appearance-none bg-transparent px-1 pr-1 md:px-3.5  leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs lg:text-base'>
                              <NumericFormat
                                displayType='text'
                                value={sumC}
                                thousandSeparator=' '
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    } else {
                      if (row.show === false) {
                        /**TODO */
                        return (
                          <tr key={row.name}>
                            <td>
                              <div className='text-left font-semibold'>
                                {row.name}{' '}
                                {row.hint ? (
                                  <HelpIcon message={row.hint} />
                                ) : (
                                  <></>
                                )}
                              </div>
                            </td>

                            <td colSpan={3}>
                              <div className='border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white shadow border-gray10 rounded'>
                                <Controller
                                  control={control2}
                                  name={
                                    `${row.form_id_1}` as keyof SecondFormInputs
                                  }
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <NumericFormat
                                      defaultValue={0}
                                      value={value}
                                      allowNegative={false}
                                      inputMode='numeric'
                                      onValueChange={(values, sourceInfo) => {
                                        onChange(values.floatValue || 0);
                                        onBlur();
                                      }}
                                      onBlur={onBlur}
                                      thousandSeparator=' '
                                      className='text-black h-full grow appearance-none bg-transparent px-3 pr-1 md:px-3.5  leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs  lg:text-base'
                                    />
                                  )}
                                />
                              </div>
                            </td>
                            <td className='hidden'>
                              <div className='hidden border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white shadow border-gray10 rounded'>
                                <Controller
                                  control={control2}
                                  name={
                                    `${row.form_id_2}` as keyof SecondFormInputs
                                  }
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <NumericFormat
                                      defaultValue={0}
                                      value={value}
                                      allowNegative={false}
                                      inputMode='numeric'
                                      onValueChange={(values, sourceInfo) => {
                                        onChange(values.floatValue || 0);
                                        onBlur();
                                      }}
                                      onBlur={onBlur}
                                      thousandSeparator=' '
                                      className='text-black h-full grow appearance-none bg-transparent px-1 pr-1 md:px-3.5  leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs  lg:text-base text-center'
                                    />
                                  )}
                                />
                              </div>
                            </td>
                            <td className='hidden'>
                              <div className='hidden border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white shadow border-gray10 rounded'>
                                <Controller
                                  control={control2}
                                  name={
                                    `${row.form_id_3}` as keyof SecondFormInputs
                                  }
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <NumericFormat
                                      defaultValue={0}
                                      value={value}
                                      allowNegative={false}
                                      inputMode='numeric'
                                      onValueChange={(values, sourceInfo) => {
                                        onChange(values.floatValue || 0);
                                        onBlur();
                                      }}
                                      onBlur={onBlur}
                                      thousandSeparator=' '
                                      className='text-black h-full grow appearance-none bg-transparent px-1 pr-1 md:px-3.5  leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs  lg:text-base text-center'
                                    />
                                  )}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr key={row.name}>
                            <td>
                              <div className='text-left font-semibold'>
                                {row.name}{' '}
                                {row.hint ? (
                                  <HelpIcon message={row.hint} />
                                ) : (
                                  <></>
                                )}
                              </div>
                            </td>

                            <td>
                              <div className='border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white shadow border-gray10 rounded'>
                                <Controller
                                  control={control2}
                                  name={
                                    `${row.form_id_1}` as keyof SecondFormInputs
                                  }
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <NumericFormat
                                      defaultValue={0}
                                      value={value}
                                      allowNegative={false}
                                      inputMode='numeric'
                                      onValueChange={(values, sourceInfo) => {
                                        onChange(values.floatValue || 0);
                                        onBlur();
                                      }}
                                      //onChange={(e) => onChange(parseInt(e.target.value))}
                                      onBlur={onBlur}
                                      thousandSeparator=' '
                                      className='text-black h-full grow appearance-none bg-transparent px-1 pr-1 xl:px-3.5  leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs  lg:text-base text-center'
                                    />
                                  )}
                                />
                              </div>
                            </td>
                            <td>
                              <div className='border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white shadow border-gray10 rounded'>
                                <Controller
                                  control={control2}
                                  name={
                                    `${row.form_id_2}` as keyof SecondFormInputs
                                  }
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <NumericFormat
                                      defaultValue={0}
                                      value={value}
                                      allowNegative={false}
                                      inputMode='numeric'
                                      onValueChange={(values, sourceInfo) => {
                                        onChange(values.floatValue || 0);
                                        onBlur();
                                      }}
                                      onBlur={onBlur}
                                      thousandSeparator=' '
                                      className='text-black h-full grow appearance-none bg-transparent px-1 pr-1 xl:px-3.5  leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs  lg:text-base text-center'
                                    />
                                  )}
                                />
                              </div>
                            </td>
                            <td>
                              <div className='border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white shadow border-gray10 rounded'>
                                <Controller
                                  control={control2}
                                  name={
                                    `${row.form_id_3}` as keyof SecondFormInputs
                                  }
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <NumericFormat
                                      defaultValue={0}
                                      value={value}
                                      allowNegative={false}
                                      inputMode='numeric'
                                      onValueChange={(values, sourceInfo) => {
                                        onChange(values.floatValue || 0);
                                        onBlur();
                                      }}
                                      onBlur={onBlur}
                                      thousandSeparator=' '
                                      className='text-black h-full grow appearance-none bg-transparent px-1 pr-1 xl:px-3.5 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs text-center lg:text-base'
                                    />
                                  )}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    }
                  })}
                  {/********************************************************************************************************************************** */}
                </tbody>
              </Table>
              <div className='flex gap-2'>
                <GuidebookModal />

                <Button
                  slotProps={{
                    root: {
                      type: 'submit',
                      component: 'button',
                    },
                  }}
                  sx={{
                    width: '100%',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    textDecoration: 'none',
                    backgroundColor: '#85bb65',
                    marginTop: '1rem',
                    '&:hover': {
                      backgroundColor: '#85bb65 !important',
                      bgcolor: '#85bb65 !important',
                      background: '#85bb65 !important',
                    },
                    '&:active': {
                      boxShadow: 'none !important',
                      backgroundColor: '#85bb65 !important',
                    },
                  }}
                  className='cursor-pointer text-white button flex items-center transition-all justify-center disabled:opacity-50 disabled:shadow-none disabled:cursor-not-allowed mt-10 w-full rounded-lg font-semibold md:mt-8 bg-[#34A853]'
                  disabled={!isValid2}
                  onClick={navigateToSummary}
                >
                  Sprawdzam poprawność danych
                </Button>
              </div>
            </form>
          </Box>
        </TabPanel>
      </Tabs>
    </Container>
  );
}
