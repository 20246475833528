import { useEffect, useState } from 'react';
import { set } from 'react-hook-form';
import toast from 'react-hot-toast';
import { DeleteForever } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Modal,
  ModalDialog,
  Stack,
  Table,
  Typography,
} from '@mui/joy';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAuthInfo } from '@propelauth/react';

import {
  VITE_45MIN_CONSULTATION_URL,
  VITE_90MIN_CONSULTATION_URL,
} from '../../utils/app/const';

import { Consultation, Consultations } from './consultationTypes';
import { getValidUrl } from './urlHelper';
import { getUsersConsultations, orderConsultation } from './useApi';

export default function PricingCard() {
  const [open, setOpen] = useState<boolean>(false);
  const [tempConsultationId, setTempConsultationId] = useState<number>(0);
  const authInfo = useAuthInfo();
  const token = authInfo.accessToken as string;
  const [totalUsedConsultations, setTotalUsedConsultations] = useState(0);
  const [consultations, setConsultations] = useState([]);

  async function fetchConsultations() {
    try {
      const data = await getUsersConsultations(token);
      setConsultations(data);
    } catch (error) {
      console.log(error);
    }
  }
  const handleConsultation = (type: string) => {
    if (type === '45min') {
      window.location.href = VITE_45MIN_CONSULTATION_URL;
    } else {
      window.location.href = VITE_90MIN_CONSULTATION_URL;
    }
  };

  const openConsultationId = async (id: number) => {
    const temp_consultations = await getUsersConsultations(token);
    if (temp_consultations.length === 0) return;
    const consultation = temp_consultations.find(
      (element: Consultation) => element.id === id
    );
    console.log(consultation);
    if (consultation) {
      window.open(getValidUrl(consultation.url), '_blank');
    } else {
      toast.error('Nie udało się otworzyć konsultacji');
    }
  };

  useEffect(() => {
    fetchConsultations();
  }, []);
  useEffect(() => {
    fetchConsultations();
  }, [totalUsedConsultations]);

  useEffect(() => {}, [consultations.length, consultations]);

  return (
    <Stack justifyContent='center' alignItems='center'>
      <div className='flex items-center justify-center'>
        <section className='relative mt-10 max-w-[1134px] px-4 sm:mt-24 sm:px-8 md:mt-0 md:px-4 md:pt-12 md:pb-20 flex w-full flex-col items-center'>
          <span className='absolute -top-12 md:top-4'></span>
          <h2 className='max-w-[452px] text-center text-base font-semibold text-gray80 md:text-2xl'>
            Wybierz konsultację, która realizuje Twoje cele biznesowe.
          </h2>
          <p className='mt-4 text-center text-xs max-w-3xl sm:text-sm'>
            Spotkaj się z naszym doradcą online(1:1), by porozmawiać o wynikach
            wyceny Twojej firmy i <br /> możliwościach podniesienia jej wartości
            w czasie.
          </p>
          <div className='mt-6 grid w-full gap-x-8 md:mt-10 md:grid-cols-2'>
            <div className='relative flex w-full flex-col rounded-2xl px-4 pb-6 pt-8 shadow md:px-8 md:pb-6 md:pt-16 md:shadow-md border border-gray10'>
              <div className='absolute left-0 top-0 right-0 h-2 rounded-t-xl bg-primary opacity-50 bg-[#85bb65]'></div>
              <h3 className='font-semibold text-gray80 md:text-2xl'>
                Konsultacja 45 minut
              </h3>

              <div className='mt-6 border-b border-gray10'></div>
              <ul className='mt-6 md:mt-8'>
                <li className='mt-5 first:mt-0 sm:last:mb-8'>
                  <div className='flex items-start gap-2'>
                    <div>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 28 31'
                        className='h-7 w-5'
                      >
                        <path
                          d='M11.361 22.313 22.002.756c.084-.184.346-.213.501-.068L27.5 5.055c.154.145.194.361.096.515L11.06 30.398c-.108.174-.387.12-.468-.065L.283 7.979c-.067-.154-.06-.35.051-.472l4.09-4.89c.08-.112.249-.059.303.065l6.634 19.63Z'
                          fill='#85bb65'
                          fillRule='nonzero'
                        ></path>
                      </svg>
                    </div>
                    <h4 className='ml-2 text-sm text-gray80 md:text-base'>
                      Wyjaśnienie metodologii wyceny.
                    </h4>
                  </div>
                </li>
                <li className='mt-5 first:mt-0 sm:last:mb-8'>
                  <div className='flex items-start gap-2'>
                    <div>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 28 31'
                        className='h-7 w-5'
                      >
                        <path
                          d='M11.361 22.313 22.002.756c.084-.184.346-.213.501-.068L27.5 5.055c.154.145.194.361.096.515L11.06 30.398c-.108.174-.387.12-.468-.065L.283 7.979c-.067-.154-.06-.35.051-.472l4.09-4.89c.08-.112.249-.059.303.065l6.634 19.63Z'
                          fill='#85bb65'
                          fillRule='nonzero'
                        ></path>
                      </svg>
                    </div>

                    <h4 className='ml-2 text-sm text-gray80 md:text-base'>
                      Omówienie otrzymanych wyników.
                    </h4>
                  </div>
                </li>
                <li className='mt-5 first:mt-0 sm:last:mb-8'>
                  <div className='flex items-start gap-2'>
                    <div>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 28 31'
                        className='h-7 w-5'
                      >
                        <path
                          d='M11.361 22.313 22.002.756c.084-.184.346-.213.501-.068L27.5 5.055c.154.145.194.361.096.515L11.06 30.398c-.108.174-.387.12-.468-.065L.283 7.979c-.067-.154-.06-.35.051-.472l4.09-4.89c.08-.112.249-.059.303.065l6.634 19.63Z'
                          fill='#85bb65'
                          fillRule='nonzero'
                        ></path>
                      </svg>
                    </div>
                    <h4 className='ml-2 text-sm text-gray80 md:text-base'>
                      Omówienie modelowego przebiegu zmiennych wpływających na
                      Twój biznes.
                    </h4>
                  </div>
                </li>
                <li className='mt-5 first:mt-0 sm:last:mb-8'>
                  <div className='flex items-start gap-2'>
                    <div>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 28 31'
                        className='h-7 w-5'
                      >
                        <path
                          d='M11.361 22.313 22.002.756c.084-.184.346-.213.501-.068L27.5 5.055c.154.145.194.361.096.515L11.06 30.398c-.108.174-.387.12-.468-.065L.283 7.979c-.067-.154-.06-.35.051-.472l4.09-4.89c.08-.112.249-.059.303.065l6.634 19.63Z'
                          fill='#85bb65'
                          fillRule='nonzero'
                        ></path>
                      </svg>
                    </div>
                    <h4 className='ml-2 text-sm text-gray80 md:text-base'>
                      Omówienie możliwości podniesienia jego wartości w
                      perspektywie czasu.
                    </h4>
                  </div>
                </li>
              </ul>
              <div
                className='cursor-pointer p-5 text-center button button--primary bg-[#85bb65] text-white font-bold flex items-center justify-center shadow mt-6 w-full rounded-lg py-3.5 text-sm sm:mt-auto md:text-base'
                onClick={() => {
                  handleConsultation('45min');
                }}
              >
                Kupuję konsultację 45 minut (600 zł)
              </div>
            </div>
            <div className='relative mt-5 flex w-full rounded-2xl px-4 pb-6 pt-8 shadow md:mt-0 md:px-8 md:pb-6 md:pt-16 md:shadow-md flex-col border border-gray10'>
              <div className='absolute left-0 top-0 right-0 h-2 rounded-t-xl bg-blue opacity-50 bg-[#B22243]'></div>
              <h3 className='font-semibold text-gray80 md:text-2xl'>
                Konsultacja 90 minut
              </h3>
              <div className='mt-6 border-b border-gray10'></div>
              <ul className='mt-6 md:mt-8'>
                <li className='mt-5 first:mt-0 sm:last:mb-8'>
                  <div className='flex items-start gap-2'>
                    <div>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 28 31'
                        className='h-7 w-5'
                      >
                        <path
                          d='M11.361 22.313 22.002.756c.084-.184.346-.213.501-.068L27.5 5.055c.154.145.194.361.096.515L11.06 30.398c-.108.174-.387.12-.468-.065L.283 7.979c-.067-.154-.06-.35.051-.472l4.09-4.89c.08-.112.249-.059.303.065l6.634 19.63Z'
                          fill='#B22243'
                          fillRule='nonzero'
                        ></path>
                      </svg>
                    </div>
                    <h4 className='ml-2 text-sm md:text-base'>
                      Wszystko co w konsultacji 45 minut.
                    </h4>
                  </div>
                </li>
                <li className='mt-5 first:mt-0 sm:last:mb-8'>
                  <div className='flex items-start gap-2'>
                    <div>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 28 31'
                        className='h-7 w-5'
                      >
                        <path
                          d='M11.361 22.313 22.002.756c.084-.184.346-.213.501-.068L27.5 5.055c.154.145.194.361.096.515L11.06 30.398c-.108.174-.387.12-.468-.065L.283 7.979c-.067-.154-.06-.35.051-.472l4.09-4.89c.08-.112.249-.059.303.065l6.634 19.63Z'
                          fill='#B22243'
                          fillRule='nonzero'
                        ></path>
                      </svg>
                    </div>
                    <h4 className='ml-2 text-sm md:text-base'>
                      Omówienie procesu analizy barier wzrostu i przygotowania
                      do skalowania.
                    </h4>
                  </div>
                </li>
                <li className='mt-5 first:mt-0 sm:last:mb-8'>
                  <div className='flex items-start gap-2'>
                    <div>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 28 31'
                        className='h-7 w-5'
                      >
                        <path
                          d='M11.361 22.313 22.002.756c.084-.184.346-.213.501-.068L27.5 5.055c.154.145.194.361.096.515L11.06 30.398c-.108.174-.387.12-.468-.065L.283 7.979c-.067-.154-.06-.35.051-.472l4.09-4.89c.08-.112.249-.059.303.065l6.634 19.63Z'
                          fill='#B22243'
                          fillRule='nonzero'
                        ></path>
                      </svg>
                    </div>
                    <h4 className='ml-2 text-sm md:text-base'>
                      Omówienie procesu skalowania biznesu i dalszych kroków
                      zmierzających do sprzedaży całości lub części udziałów w
                      firmie, konsolidacji lub sukcesji.
                    </h4>
                  </div>
                </li>
              </ul>
              <div
                className='cursor-pointer p-5 text-center button button--secondary bg-[#B22243] text-white font-bold flex items-center justify-center shadow mt-6 w-full rounded-lg py-3.5 text-sm sm:mt-auto md:text-base'
                onClick={() => {
                  handleConsultation('90-min');
                }}
              >
                Kupuję konsultację 90 minut (1200 zł)
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal open={open} onClose={() => setOpen(true)}>
        <ModalDialog variant='outlined' role='alertdialog'>
          <DialogTitle>Uwaga</DialogTitle>
          <Divider />
          <DialogContent>
            Hej wykorzystujesz właśnie konsultacje. Przeniesiemy cię zaraz na
            stronę calendly do umawiania spotkania. Jeśli z jakiegoś powodu
            przerwiesz ten proces, sprawdź swoją skrzynkę e-mail, znajdziesz tam
            link do wznowienia.
          </DialogContent>
          <DialogActions>
            <Button
              variant='solid'
              color='danger'
              onClick={() => {
                openConsultationId(tempConsultationId);
                setOpen(false);
              }}
            >
              OK
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>

      <Stack justifyContent='center' alignItems='center'>
        {consultations.length >= 1 ? (
          <div>
            <Typography level='h2'>Twoje konsultacje</Typography>
            <Box sx={{ p: '1rem', m: '1rem', display: 'flex', gap: '16px' }}>
              <Table sx={{ '& thead th:first-of-type': { width: '80%' } }}>
                <thead>
                  <tr>
                    <th>Konsultacja</th>
                    <th>Wykorzystanie</th>
                  </tr>
                </thead>
                <tbody key={totalUsedConsultations}>
                  {consultations.map((consultation: Consultation) => (
                    <tr key={consultation.id}>
                      <td>
                        {consultation.consultation_type === 'Konsultacja 45-min'
                          ? 'Konsultacja 45 minut z doradcą ds. wyceny i skalowania biznesu'
                          : 'Konsultacja 90 minut z doradcą ds. wyceny i skalowania biznesu'}
                      </td>
                      <td className={totalUsedConsultations.toString()}>
                        {consultation.used_consultation ? (
                          <div>
                            <Box
                              sx={{
                                textDecoration: 'line-through',
                              }}
                            >
                              Konsultacja wykorzystana
                            </Box>
                          </div>
                        ) : (
                          <div>
                            <Button
                              onClick={async () => {
                                await orderConsultation(
                                  token,
                                  consultation.consultation_type
                                );
                                setTotalUsedConsultations(
                                  (totalUsedConsultations) =>
                                    totalUsedConsultations + 1
                                );
                                setTempConsultationId(consultation.id);
                                setOpen(true);
                              }}
                            >
                              Zabookuj konsultację
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>
          </div>
        ) : (
          <>Obecnie nie posiadasz wykupionej konsultacji</>
        )}
      </Stack>
    </Stack>
  );
}
