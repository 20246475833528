import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Table, Typography } from '@mui/joy';
import { useAuthInfo } from '@propelauth/react';

import HelpIcon from './HelpIcon/HelpIcon.tsx';
import {
  fetchFirstFormData,
  fetchSecondFormData,
  prefillSecondFormData,
  saveFirstFormData,
  saveSocondFormData,
} from './ApiService.ts';
import { firstFormOptions, secondFormOptions } from './forms.ts';
import { FirstFormInputs, SecondFormInputs } from './FormsTypes.ts';
import { firstFormSchema, secondFormSchema } from './ZodValidator.ts';

import './CustomForm.module.css'; // Import css modules stylesheet as styles

const rows = secondFormOptions;

export default function PdfSummary() {
  const authInfo = useAuthInfo();
  const accessToken = authInfo.accessToken;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    // reset,
  } = useForm<FirstFormInputs>({
    mode: 'onChange',
    resolver: zodResolver(firstFormSchema),
    defaultValues: async () => fetchFirstFormData(accessToken as string),
  });
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    control: control2,
    // setValue: setValue2,
    formState: { errors: errors2 },
  } = useForm<SecondFormInputs>({
    mode: 'onChange',
    resolver: zodResolver(secondFormSchema),
    defaultValues: async () => prefillSecondFormData(accessToken as string),
  });

  const onSubmit: SubmitHandler<FirstFormInputs> = (data) =>
    saveFirstFormData(data, accessToken as string);

  const onSubmit2: SubmitHandler<SecondFormInputs> = async (data) => {
    const saveSecondFData = await saveSocondFormData(
      data,
      accessToken as string
    );
    // if data saved then navigate to summary page
    saveSecondFData.then(() => {
      navigate('/summary');
    });
  };

  return (
    <div>
      <div className='mt-[1rem] mb-[1rem]'>
        <Typography level='h2' className='text-left'>
          Podsumowanie
        </Typography>
      </div>
      {/********************************************************************************PROBLEMHERE************************************************** */}
      <div>
        <div className='absolute left-0 top-0 h-4 w-full bg-[#1a73e8] opacity-80'></div>
        <div className='mt-[1rem] mb-[1rem]'>
          <Typography level='h2' className='text-left'>
            Informacje o działalności
          </Typography>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className='text-left mb-8'>
                <Typography>
                  Dane, które wprowadzasz w tej zakładce, mają bezpośredni wpływ
                  na wycenę rynkową Twojej firmy i nie będziemy ich nikomu
                  udostępniać. Szegółowe informacje wyjaśniajace każdą pozycję
                  znajdziesz w naszym przewodniku.
                </Typography>
              </div>
              <div className='w-full'>
                <div className='grid grid-cols-1 items-center sm:grid-cols-1 gap-2'>
                  {/********************************************************************************************************************************** */}
                  {/** FIRST FORM */}
                  {firstFormOptions.map((option, index) => (
                    <div
                      className='grid grid-cols-1 items-center sm:grid-cols-2'
                      key={index}
                    >
                      <div className='flex items-center text-sm font-semibold text-gray80 sm:pb-2'>
                        <Typography>
                          {option.title}
                          <HelpIcon message={option.hint} />
                        </Typography>
                      </div>
                      <div className='border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white border-gray10 rounded'>
                        {option.key === 'sector' ? (
                          <>
                            {Array.from({ length: 3 }, (_, index) => (
                              <select
                                key={index}
                                {...register(
                                  `${option.key}${
                                    index !== undefined ? `_${index + 1}` : ''
                                  }` as keyof FirstFormInputs
                                )}
                                placeholder={option.placeholder}
                                className={`text-black h-full grow appearance-none bg-transparent border-r px-2 pr-2 md:px-3.5 md:pr-8 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base ${
                                  index === 0 ? '' : 'hidden'
                                }`}
                                disabled
                              >
                                {option.answers.map((answer) => (
                                  <option
                                    key={answer.value}
                                    value={answer.value}
                                  >
                                    {answer.label}
                                  </option>
                                ))}
                              </select>
                            ))}
                          </>
                        ) : (
                          <>
                            <select
                              {...register(
                                `${option.key}` as keyof FirstFormInputs,
                                {
                                  setValueAs: (value) => {
                                    if (value === 'true') {
                                      return true;
                                    } else if (value === 'false') {
                                      return false;
                                    } else {
                                      return value;
                                    }
                                  },
                                }
                              )}
                              placeholder={option.placeholder}
                              className='text-black h-full grow appearance-none bg-transparent px-3.5 pr-8 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'
                              disabled
                            >
                              {option.answers.map((answer) => (
                                <option key={answer.value} value={answer.value}>
                                  {answer.label}
                                </option>
                              ))}
                            </select>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                  {/** END OF FIRST FORM */}
                  {/********************************************************************************************************************************** */}
                </div>
              </div>
            </div>
          </form>
          <div className='mt-[1rem] mb-[1rem]'>
            <Typography level='h2' className='text-left'>
              Dane finansowe
            </Typography>
          </div>
          <div>
            <form onSubmit={handleSubmit2(onSubmit2)}>
              <Table id='report-1'>
                <thead>
                  <tr className='text-base font-bold'>
                    <th
                      style={{
                        width: '60%',
                      }}
                    >
                      Rok{' '}
                      <HelpIcon
                        message={`Prosimy o wprowadzenie danych księgowych z RZiS lub innego dokumentu księgowego za dany rok obrachunkowy.`}
                      />
                    </th>
                    <th>2023</th>
                    <th>2022</th>
                    <th>2021</th>
                  </tr>
                </thead>
                <tbody>
                  {/********************************************************************************************************************************** */}
                  {/** SECOND FORM  */}
                  {rows.map((row) => {
                    if (row.suma) {
                      return (
                        <tr
                          key={row.name}
                          style={{
                            // background: row.suma ? '#F5F5F5' : '#fff',
                            background: 'white',
                          }}
                        >
                          <td>
                            <div className='text-left font-bold text-base my-5'>
                              {row.name}{' '}
                              {row.hint ? (
                                <HelpIcon message={row.hint} />
                              ) : (
                                <></>
                              )}
                            </div>
                            <ErrorMessage
                              errors={errors2}
                              name={row.form_id_1 as keyof SecondFormInputs}
                              render={({ message }) => (
                                <Box
                                  sx={{
                                    color: 'red',
                                  }}
                                >
                                  2023 {message}
                                </Box>
                              )}
                            />
                            <ErrorMessage
                              errors={errors2}
                              name={row.form_id_2 as keyof SecondFormInputs}
                              render={({ message }) => (
                                <Box
                                  sx={{
                                    color: 'red',
                                  }}
                                >
                                  2022 {message}
                                </Box>
                              )}
                            />
                            <ErrorMessage
                              errors={errors2}
                              name={row.form_id_3 as keyof SecondFormInputs}
                              render={({ message }) => (
                                <Box
                                  sx={{
                                    color: 'red',
                                  }}
                                >
                                  2021 {message}
                                </Box>
                              )}
                            />
                          </td>

                          <td>
                            <div className='flex items-center text-black text-left h-full grow appearance-none bg-transparent px-3.5 pr-8 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'>
                              <Controller
                                control={control2}
                                name={
                                  `${row.form_id_1}` as keyof SecondFormInputs
                                }
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <NumericFormat
                                    defaultValue={0}
                                    value={value}
                                    allowNegative={false}
                                    inputMode='numeric'
                                    onValueChange={(values, sourceInfo) => {
                                      onChange(values.floatValue);
                                    }}
                                    //onChange={(e) => onChange(parseInt(e.target.value))}
                                    onBlur={onBlur}
                                    thousandSeparator=' '
                                    className='text-slate-400 h-full grow appearance-none bg-transparent  pr-8 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'
                                    disabled
                                  />
                                )}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='flex items-center text-black text-left h-full grow appearance-none bg-transparent px-3.5 pr-8 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'>
                              <Controller
                                control={control2}
                                name={
                                  `${row.form_id_2}` as keyof SecondFormInputs
                                }
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <NumericFormat
                                    defaultValue={0}
                                    value={value}
                                    allowNegative={false}
                                    inputMode='numeric'
                                    onValueChange={(values, sourceInfo) => {
                                      onChange(values.floatValue);
                                    }}
                                    onBlur={onBlur}
                                    thousandSeparator=' '
                                    className='text-slate-400 h-full grow appearance-none bg-transparent  pr-8 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'
                                    disabled
                                  />
                                )}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='flex items-center text-black text-left h-full grow appearance-none bg-transparent px-3.5 pr-8 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'>
                              <Controller
                                control={control2}
                                name={
                                  `${row.form_id_3}` as keyof SecondFormInputs
                                }
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <NumericFormat
                                    defaultValue={0}
                                    value={value}
                                    allowNegative={false}
                                    inputMode='numeric'
                                    onValueChange={(values, sourceInfo) => {
                                      onChange(values.floatValue);
                                    }}
                                    onBlur={onBlur}
                                    thousandSeparator=' '
                                    className='text-slate-400 h-full grow appearance-none bg-transparent  pr-8 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'
                                    disabled
                                  />
                                )}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    } else {
                      if (row.show === false) {
                        /**TODO */
                        return (
                          <tr key={row.name}>
                            <td>
                              <div className='text-left font-semibold'>
                                {row.name}{' '}
                                {row.hint ? (
                                  <HelpIcon message={row.hint} />
                                ) : (
                                  <></>
                                )}
                              </div>
                            </td>

                            <td colSpan={3}>
                              <div className='border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white shadow border-gray10 rounded'>
                                <Controller
                                  control={control2}
                                  name={
                                    `${row.form_id_1}` as keyof SecondFormInputs
                                  }
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <NumericFormat
                                      defaultValue={0}
                                      value={value}
                                      allowNegative={false}
                                      inputMode='numeric'
                                      onValueChange={(values, sourceInfo) => {
                                        onChange(values.floatValue);
                                      }}
                                      //onChange={(e) => onChange(parseInt(e.target.value))}
                                      onBlur={onBlur}
                                      thousandSeparator=' '
                                      className='text-slate-400 h-full grow appearance-none bg-transparent px-1 pr-1 lg:px-3.5 lg:pr-8 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'
                                      disabled
                                    />
                                  )}
                                />
                              </div>
                            </td>
                            <td className='hidden'>
                              <div className='hidden border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white shadow border-gray10 rounded'>
                                <Controller
                                  control={control2}
                                  name={
                                    `${row.form_id_2}` as keyof SecondFormInputs
                                  }
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <NumericFormat
                                      defaultValue={0}
                                      value={value}
                                      allowNegative={false}
                                      inputMode='numeric'
                                      onValueChange={(values, sourceInfo) => {
                                        onChange(values.floatValue);
                                      }}
                                      onBlur={onBlur}
                                      thousandSeparator=' '
                                      className='text-slate-400 h-full grow appearance-none bg-transparent px-1 pr-1 lg:px-3.5 lg:pr-8 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'
                                    />
                                  )}
                                />
                              </div>
                            </td>
                            <td className='hidden'>
                              <div className='hidden border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white shadow border-gray10 rounded'>
                                <Controller
                                  control={control2}
                                  name={
                                    `${row.form_id_3}` as keyof SecondFormInputs
                                  }
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <NumericFormat
                                      defaultValue={0}
                                      value={value}
                                      allowNegative={false}
                                      inputMode='numeric'
                                      onValueChange={(values, sourceInfo) => {
                                        onChange(values.floatValue);
                                      }}
                                      onBlur={onBlur}
                                      thousandSeparator=' '
                                      className='text-slate-400 h-full grow appearance-none bg-transparent px-1 pr-1 lg:px-3.5 lg:pr-8 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'
                                      disabled
                                    />
                                  )}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr key={row.name}>
                            <td>
                              <div className='text-left font-semibold'>
                                {row.name}{' '}
                                {row.hint ? (
                                  <HelpIcon message={row.hint} />
                                ) : (
                                  <></>
                                )}
                              </div>
                            </td>

                            <td>
                              <div className='border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white shadow border-gray10 rounded'>
                                <Controller
                                  control={control2}
                                  name={
                                    `${row.form_id_1}` as keyof SecondFormInputs
                                  }
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <NumericFormat
                                      defaultValue={0}
                                      value={value}
                                      allowNegative={false}
                                      inputMode='numeric'
                                      onValueChange={(values, sourceInfo) => {
                                        onChange(values.floatValue);
                                      }}
                                      onBlur={onBlur}
                                      thousandSeparator=' '
                                      className='text-slate-400 h-full grow appearance-none bg-transparent px-3.5 pr-8 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'
                                      disabled
                                    />
                                  )}
                                />
                              </div>
                            </td>
                            <td>
                              <div className='border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white shadow border-gray10 rounded'>
                                <Controller
                                  control={control2}
                                  name={
                                    `${row.form_id_2}` as keyof SecondFormInputs
                                  }
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <NumericFormat
                                      defaultValue={0}
                                      value={value}
                                      allowNegative={false}
                                      inputMode='numeric'
                                      onValueChange={(values, sourceInfo) => {
                                        onChange(values.floatValue);
                                      }}
                                      onBlur={onBlur}
                                      thousandSeparator=' '
                                      className='text-slate-400 h-full grow appearance-none bg-transparent px-3.5 pr-8 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'
                                      disabled
                                    />
                                  )}
                                />
                              </div>
                            </td>
                            <td>
                              <div className='border h-12 outline-none focus-within:border-blue focus-within:outline-none relative flex items-center justify-center transition-all duration-150 cursor-text bg-white shadow border-gray10 rounded'>
                                <Controller
                                  control={control2}
                                  name={
                                    `${row.form_id_3}` as keyof SecondFormInputs
                                  }
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <NumericFormat
                                      defaultValue={0}
                                      value={value}
                                      allowNegative={false}
                                      inputMode='numeric'
                                      onValueChange={(values, sourceInfo) => {
                                        onChange(values.floatValue);
                                      }}
                                      onBlur={onBlur}
                                      thousandSeparator=' '
                                      className='text-slate-400 h-full grow appearance-none bg-transparent px-3.5 pr-8 leading-tight outline-none  w-full min-w-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs md:text-base'
                                      disabled
                                    />
                                  )}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    }
                  })}
                  {/********************************************************************************************************************************** */}
                </tbody>
              </Table>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
