import { Container, Typography } from '@mui/joy';

export const NotFound = () => {
  return (
    <>
      <Container>
        <Typography level='h1'>Page not found</Typography>
      </Container>
    </>
  );
};
