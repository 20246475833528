import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from '@mui/joy';
type HelpIconProps = {
  message: string;
};

export default function HelpIcon({ message }: HelpIconProps) {
  return (
    <Tooltip
      title={message}
      variant='solid'
      placement='bottom-start'
      color='primary'
      sx={{
        maxWidth: 320,
      }}
    >
      <HelpOutlineIcon
        sx={{
          fontSize: '16px',
        }}
      />
    </Tooltip>
  );
}
