import {
  FINANCIAL_RAPORT_API_URL,
  RAPORT_GENERATION_API_URL,
} from '../../utils/app/const';

import { RaportType } from './raportTypes';
export interface RaportGenerationResponse {
  created_at: string;
  updated_at: string;
  id: number;
  user_id: string;
  generated_reports_counter: number;
  generated_reports_limit: number;
}

export interface GetGenerationResponse {
  generated_reports: number;
  generated_reports_limit: number;
  reports_left: number;
}

export const getDashboardData = async (token: string) => {
  try {
    const response: RaportType = await fetch(FINANCIAL_RAPORT_API_URL, {
      headers: {
        // prettier-ignore
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        return data;
      });

    const types = await response;
    return types;
  } catch (error) {
    console.error('Error fetching consultation types:', error);
    throw error; // Re-throw the error to let the calling code handle it
  }
};

export const getRaportData = async (token: string) => {
  try {
    const response = await fetch(FINANCIAL_RAPORT_API_URL, {
      headers: {
        // prettier-ignore
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const types = await response.json();
    return types;
  } catch (error) {
    console.error('Error fetching financial raport types:', error);
    throw error; // Re-throw the error to let the calling code handle it
  }
};

/**
 * Generations
 */

export const getLeftGenerations = async (token: string) => {
  try {
    // fetch FINANCIAL_RAPORT_API_URL get
    const response: GetGenerationResponse = await fetch(
      RAPORT_GENERATION_API_URL,
      {
        headers: {
          // prettier-ignore
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        return data;
      });

    const types: GetGenerationResponse = await response;
    return types;
  } catch (error) {
    console.error('Error fetching left generations:', error);
    throw error; // Re-throw the error to let the calling code handle it
  }
};

export const countUsedRaportsGeneration = async (token: string) => {
  try {
    const response = await fetch(RAPORT_GENERATION_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // prettier-ignore
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const types: RaportGenerationResponse = await response.json();
    //toast.success('Raport został wygenerowany!');
    return types;
  } catch (error) {
    console.error('Wystąpił błąd:', error);
    throw error; // Re-throw the error to let the calling code handle it
  }
};

/**
 * Can user generate raport
 * @param token
 * @returns boolean
 */

export const canUserGenerateRaport = async (token: string) => {
  // if v1/generated-reports return 500 then true
  const generations = await getLeftGenerations(token);
  // if (
  //   generations.generated_reports_counter < generations.generated_reports_limit
  // ) {
  //   return true;
  // } else {
  //   return false;
  // }
};
