import Summary from '../components/Summary/Summary';
import BasicAppPageWithSidebarTemplate from '../templates/BasicAppPageWithSidebarTemplate/BasicAppPageWithSidebarTemplate';

export default function SummaryPage() {
  return (
    <BasicAppPageWithSidebarTemplate>
      <Summary />
    </BasicAppPageWithSidebarTemplate>
  );
}
