export const API_ENTRYPOINT = import.meta.env.API_ENTRYPOINT;
export const AUTH_URL = import.meta.env.VITE_AUTH_URL;
export const VITE_90MIN_CONSULTATION_URL = import.meta.env
  .VITE_90MIN_CONSULTATION_URL;
export const VITE_45MIN_CONSULTATION_URL = import.meta.env
  .VITE_45MIN_CONSULTATION_URL;
export const BUSINESS_API_URL =
  import.meta.env.VITE_BUSINESS_API_URL || 'http://localhost:8020/v1/business';
export const FINANCIAL_API_URL =
  import.meta.env.VITE_FINANCIAL_API_URL ||
  'http://localhost:8020/v1/financial';
export const FINANCIAL_RAPORT_API_URL =
  import.meta.env.VITE_FINANCIAL_RAPORT_API_URL ||
  'http://localhost:8020/v1/financial-report';
export const USERS_API_URL =
  import.meta.env.VITE_USERS_API_URL || 'http://localhost:8020/v1/users';
export const CONSULTATIONS_URL =
  import.meta.env.VITE_CONSULTATIONS_URL ||
  'http://localhost:8020/v1/consultations';
export const REDIRECT_URL =
  import.meta.env.VITE_REDIRECT_URL || 'https://growhub.com.pl/zaloguj';

export const RAPORT_GENERATION_API_URL =
  import.meta.env.VITE_RAPORT_GENERATION_API_URL ||
  'http://localhost:8020/v1/generated-reports';

export const CURRENT_YEARS = [2023, 2022, 2021];
