import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import toast from 'react-hot-toast';
import { ListAlt } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import {
  Box,
  Button,
  Divider,
  Sheet,
  Stack,
  Table,
  Typography,
} from '@mui/joy';
import { useAuthInfo } from '@propelauth/react';

import { USERS_API_URL } from '../../utils/app/const';

interface usersType {
  first_name: string;
  user_id: string;
  update_password_required: boolean;
  picture_url: string;
  mfa_enabled: boolean;
  metadata: metadataType;
  locked: boolean;
  last_active_at: number;
  has_password: boolean;
  enabled: boolean;
  email_confirmed: boolean;
  email: string;
  created_at: number;
  can_create_orgs: false;
  properties: propertiesType;
}

interface metadataType {
  package_type?: string;
  isAdmin?: boolean;
}
interface propertiesType {
  metadata: metadataType;
}

export default function AdminPanel() {
  const [adminPermission, setAdminPermission] = useState(false);
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [users, setUsers] = useState<usersType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMoreResults, setHasMoreResults] = useState(true);
  const [page, setPage] = useState(0);
  const useAuth = useAuthInfo();
  const [uniqueUsers, setUniqueUsers] = useState<usersType[]>([]);
  useEffect(() => {
    if (typeof useAuth?.user?.properties?.metadata !== 'undefined') {
      const isAdmin = (
        useAuth.user.properties.metadata as { isAdmin?: boolean }
      )?.isAdmin;
      if (isAdmin) {
        setAdminPermission(true);
      }
    }
  }, [
    adminPermission,
    useAuth?.user?.properties?.isAdmin,
    useAuth?.user?.properties?.metadata,
  ]);

  useEffect(() => {
    const fetchUsers = () => {
      function removeDuplicates(arr: usersType[]) {
        const seen = new Map();
        return arr.filter((item) => {
          const key = item['user_id'];
          if (!seen.has(key)) {
            seen.set(key, true);
            return true;
          }
          return false;
        });
      }
      if (!hasMoreResults && isLoading) {
        const uniqueArray = removeDuplicates(users);
        setUsers(uniqueArray);
        setUniqueUsers(uniqueArray);
        setIsLoading(false);
        return;
      }
      if (hasMoreResults) {
        fetch(USERS_API_URL + `/${page}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${useAuth.accessToken}`,
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((responseData) => {
            setUsers((prevUsers) => [...prevUsers, ...responseData.users]);
            setHasMoreResults(responseData.has_more_results);
            setPage((prevPage) => prevPage + 1);
          })
          .catch((error) => {
            toast.error('API fetch error', error);
          });
      }
    };

    fetchUsers();
  }, [useAuth.accessToken, hasMoreResults, isLoading, page, users]);

  useEffect(() => {
    const newArray = [['Imię', 'Email', 'Pakiet']];
    uniqueUsers.map((user: usersType) => {
      let package_type;
      if (!user.properties?.metadata?.package_type) {
        package_type = 'Brak pakietu';
      } else {
        package_type = user.properties?.metadata?.package_type;
      }
      newArray.push([user.first_name, user.email, package_type]);
    });
    setCsvData(newArray);
  }, [uniqueUsers]);

  return (
    <>
      {adminPermission && (
        <Stack
          sx={{ p: 2, width: '100%' }}
          justifyContent='center'
          alignItems='center'
        >
          <Typography level='h1' startDecorator={<SupervisorAccountIcon />}>
            Admin Panel
          </Typography>
          <Divider orientation='horizontal' sx={{ my: 2 }} />
          <Box sx={{ width: '100%' }}>
            <Typography level='body-sm' textAlign='center' sx={{ pb: 2 }}>
              Lista użytkowników
            </Typography>
            <Sheet
              variant='outlined'
              sx={{
                '--TableCell-height': '40px',
                // the number is the amount of the header rows.
                '--TableHeader-height': 'calc(1 * var(--TableCell-height))',
                '--Table-firstColumnWidth': '80px',
                '--Table-lastColumnWidth': '144px',
                // background needs to have transparency to show the scrolling shadows
                '--TableRow-stripeBackground': 'rgba(0 0 0 / 0.04)',
                '--TableRow-hoverBackground': 'rgba(0 0 0 / 0.08)',
                overflow: 'auto',
                backgroundSize:
                  '40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))',
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'local, local, scroll, scroll',
                backgroundPosition:
                  'var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)',
                backgroundColor: 'background.surface',
              }}
            >
              <Table borderAxis='bothBetween' stripe='odd' hoverRow>
                <thead>
                  <tr>
                    <th style={{ width: 'var(--Table-firstColumnWidth)' }}>
                      Index
                    </th>
                    <th style={{ width: 250 }}>Imię</th>
                    <th style={{ width: 350 }}>E-mail</th>
                    <th style={{ width: 200 }}>Pakiet</th>
                    <th
                      aria-label='last'
                      style={{ width: 'var(--Table-lastColumnWidth)' }}
                    />
                  </tr>
                </thead>
                <tbody>
                  {uniqueUsers &&
                    uniqueUsers?.map((user: usersType, index: number) => (
                      <tr key={index}>
                        <td>{index}</td>
                        <td>{user.first_name}</td>
                        <td>{user.email}</td>
                        <td>
                          {user.properties?.metadata &&
                            user.properties?.metadata?.package_type}
                          {!user.properties?.metadata && 'Brak pakietu'}
                        </td>
                        <td>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            <Button
                              size='sm'
                              variant='soft'
                              color='danger'
                              startDecorator={<ClearIcon />}
                            >
                              Usuń
                            </Button>
                          </Box>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {isLoading && (
                <Stack justifyContent='center' alignItems='center'>
                  <Button loading loadingPosition='start' variant='plain'>
                    Wczytywanie użytkowników
                  </Button>
                </Stack>
              )}
            </Sheet>
          </Box>
          <Stack justifyContent='center' alignItems='center' sx={{ mt: 5 }}>
            {csvData.length > 1 && (
              <CSVLink data={csvData} filename={'users-list.csv'}>
                <Button sx={{ maxWidth: 200 }} startDecorator={<ListAlt />}>
                  Eksportuj do CSV
                </Button>
              </CSVLink>
            )}
          </Stack>
          <Divider orientation='horizontal' sx={{ my: 2 }} />
        </Stack>
      )}
      {!adminPermission && (
        <Typography level='h1'>
          Nie masz uprawnień do wyświetlenia tej strony
        </Typography>
      )}
    </>
  );
}
