import toast from 'react-hot-toast';

import { CONSULTATIONS_URL } from '../../utils/app/const';
const CONSULTATIONS_TYPES_URL = CONSULTATIONS_URL + '/types';
const CONSULTATIONS_BOOK_URL = CONSULTATIONS_URL + '/book';

export const getConsultationTypes = async () => {
  try {
    const response = await fetch(CONSULTATIONS_TYPES_URL);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const types = await response.json();
    return types;
  } catch (error) {
    console.error('Error fetching consultation types:', error);
    throw error; // Re-throw the error to let the calling code handle it
  }
};

export const getUsersConsultations = async (token: string) => {
  try {
    const response = await fetch(CONSULTATIONS_URL, {
      headers: {
        // prettier-ignore
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const types = await response.json();
    return types;
  } catch (error) {
    console.error('Error fetching consultation types:', error);
    throw error; // Re-throw the error to let the calling code handle it
  }
};

export const hasUserConsultation = async (token: string) => {
  const consultations = await getUsersConsultations(token);
  if (consultations.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const orderConsultation = async (
  token: string,
  consultationType: string
) => {
  try {
    const response = await fetch(CONSULTATIONS_BOOK_URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        // prettier-ignore
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        consultation_type: consultationType,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const types = await response.json();
    toast.success('Consultation booked!');
    return types;
  } catch (error) {
    console.error('Error fetching consultation types:', error);
    throw error; // Re-throw the error to let the calling code handle it
  }
};
