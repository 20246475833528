import toast from 'react-hot-toast';

import { BUSINESS_API_URL, FINANCIAL_API_URL } from '../../utils/app/const';

import {
  FirstFormInputs,
  PutRequest,
  SecondFormInputs,
  SecondFormQuery,
} from './FormsTypes';

/*********************************************************************************** */
/** 1-st and 2-nd form qury string preparation function */
/*********************************************************************************** */
export async function prepareQuery(
  data: SecondFormInputs,
  queryType: string,
  year?: number
) {
  let baseQuery: SecondFormQuery;
  if (queryType == 'POST') {
    // prettier-ignore
    baseQuery = {
  "years": [
    {
      "year": 2020,
      "net_revenue":
        data[`net_revenue_2020` as keyof SecondFormInputs],
      "operating_profit":
        data[`operating_profit_2020` as keyof SecondFormInputs],
      "depreciation": data[`depreciation_2020` as keyof SecondFormInputs],
      "total_cash":
        data[
          `total_cash_2020` as keyof SecondFormInputs
        ],
      "commitments": data[`commitments_2020` as keyof SecondFormInputs],
      "three_month_salary_budget":
        data[
          `three_month_salary_budget_2020` as keyof SecondFormInputs
        ],
      "receivables_not_overdue":
        data[
          `receivables_not_overdue_2020` as keyof SecondFormInputs
        ],
      "supplies": data[`supplies_2020` as keyof SecondFormInputs],
      "a_lands": data[`a_lands_2020` as keyof SecondFormInputs],
      "b_buildings": data[`b_buildings_2020` as keyof SecondFormInputs],
      "c_technical_infrastructure":
        data[`c_technical_infrastructure_2020` as keyof SecondFormInputs],
      "d_transport": data[`d_transport_2020` as keyof SecondFormInputs],
      "e_other_assets":
        data[`e_other_assets_2020` as keyof SecondFormInputs],
    },
    {
      "year": 2021,
      "net_revenue":
        data[`net_revenue_2021` as keyof SecondFormInputs],
      "operating_profit":
        data[`operating_profit_2021` as keyof SecondFormInputs],
      "depreciation": data[`depreciation_2021` as keyof SecondFormInputs],
      "total_cash":
        data[
          `total_cash_2021` as keyof SecondFormInputs
        ],
      "commitments": data[`commitments_2021` as keyof SecondFormInputs],
      "three_month_salary_budget":
        data[
          `three_month_salary_budget_2021` as keyof SecondFormInputs
        ],
      "receivables_not_overdue":
        data[
          `receivables_not_overdue_2021` as keyof SecondFormInputs
        ],
      "supplies": data[`supplies_2021` as keyof SecondFormInputs],
      "a_lands": data[`a_lands_2021` as keyof SecondFormInputs],
      "b_buildings": data[`b_buildings_2021` as keyof SecondFormInputs],
      "c_technical_infrastructure":
        data[`c_technical_infrastructure_2021` as keyof SecondFormInputs],
      "d_transport": data[`d_transport_2021` as keyof SecondFormInputs],
      "e_other_assets":
        data[`e_other_assets_2021` as keyof SecondFormInputs],
    },
    {
      "year": 2022,
      "net_revenue":
        data[`net_revenue_2022` as keyof SecondFormInputs],
      "operating_profit":
        data[`operating_profit_2022` as keyof SecondFormInputs],
      "depreciation": data[`depreciation_2022` as keyof SecondFormInputs],
      "total_cash":
        data[
          `total_cash_2022` as keyof SecondFormInputs
        ],
      "commitments": data[`commitments_2022` as keyof SecondFormInputs],
      "three_month_salary_budget":
        data[
          `three_month_salary_budget_2022` as keyof SecondFormInputs
        ],
      "receivables_not_overdue":
        data[
          `receivables_not_overdue_2022` as keyof SecondFormInputs
        ],
      "supplies": data[`supplies_2022` as keyof SecondFormInputs],
      "a_lands": data[`a_lands_2022` as keyof SecondFormInputs],
      "b_buildings": data[`b_buildings_2022` as keyof SecondFormInputs],
      "c_technical_infrastructure":
        data[`c_technical_infrastructure_2022` as keyof SecondFormInputs],
      "d_transport": data[`d_transport_2022` as keyof SecondFormInputs],
      "e_other_assets":
        data[`e_other_assets_2022` as keyof SecondFormInputs],
    },
  ],
};
    return baseQuery;
  }
  if (queryType == 'PUT') {
    if (year === 2020) {
      // prettier-ignore
      const putQuery : PutRequest = {
      "net_revenue": data[`net_revenue_2020` as keyof SecondFormInputs],
      "operating_profit": data[`operating_profit_2020` as keyof SecondFormInputs],
      "year": 2020,
      "depreciation": data[`depreciation_2020` as keyof SecondFormInputs],
      "total_cash": data[`total_cash_2020` as keyof SecondFormInputs],
      "commitments": data[`commitments_2020` as keyof SecondFormInputs],
      "three_month_salary_budget": data[`three_month_salary_budget_2020` as keyof SecondFormInputs],
      "receivables_not_overdue": data[`receivables_not_overdue_2020` as keyof SecondFormInputs],
      "supplies": data[`supplies_2020` as keyof SecondFormInputs],
      "a_lands": data[`a_lands_2020` as keyof SecondFormInputs],
      "b_buildings": data[`b_buildings_2020` as keyof SecondFormInputs],
      "c_technical_infrastructure": data[`c_technical_infrastructure_2020` as keyof SecondFormInputs],
      "d_transport": data[`d_transport_2020` as keyof SecondFormInputs],
      "e_other_assets": data[`e_other_assets_2020` as keyof SecondFormInputs],
    }
      return putQuery;
    }
    if (year === 2021) {
      // prettier-ignore
      const putQuery : PutRequest = {
     "net_revenue": data[`net_revenue_2021` as keyof SecondFormInputs],
     "operating_profit": data[`operating_profit_2021` as keyof SecondFormInputs],
     "year": 2021,
     "depreciation": data[`depreciation_2021` as keyof SecondFormInputs],
     "total_cash": data[`total_cash_2021` as keyof SecondFormInputs],
     "commitments": data[`commitments_2021` as keyof SecondFormInputs],
     "three_month_salary_budget": data[`three_month_salary_budget_2021` as keyof SecondFormInputs],
     "receivables_not_overdue": data[`receivables_not_overdue_2021` as keyof SecondFormInputs],
     "supplies": data[`supplies_2021` as keyof SecondFormInputs],
     "a_lands": data[`a_lands_2021` as keyof SecondFormInputs],
     "b_buildings": data[`b_buildings_2021` as keyof SecondFormInputs],
     "c_technical_infrastructure": data[`c_technical_infrastructure_2021` as keyof SecondFormInputs],
     "d_transport": data[`d_transport_2021` as keyof SecondFormInputs],
     "e_other_assets": data[`e_other_assets_2021` as keyof SecondFormInputs],
   }
      return putQuery;
    }
    if (year === 2022) {
      // prettier-ignore
      const putQuery : PutRequest = {
   "net_revenue": data[`net_revenue_2022` as keyof SecondFormInputs],
   "operating_profit": data[`operating_profit_2022` as keyof SecondFormInputs],
   "year": 2022,
   "depreciation": data[`depreciation_2022` as keyof SecondFormInputs],
   "total_cash": data[`total_cash_2022` as keyof SecondFormInputs],
   "commitments": data[`commitments_2022` as keyof SecondFormInputs],
   "three_month_salary_budget": data[`three_month_salary_budget_2022` as keyof SecondFormInputs],
   "receivables_not_overdue": data[`receivables_not_overdue_2022` as keyof SecondFormInputs],
   "supplies": data[`supplies_2022` as keyof SecondFormInputs],
   "a_lands": data[`a_lands_2022` as keyof SecondFormInputs],
   "b_buildings": data[`b_buildings_2022` as keyof SecondFormInputs],
   "c_technical_infrastructure": data[`c_technical_infrastructure_2022` as keyof SecondFormInputs],
   "d_transport": data[`d_transport_2022` as keyof SecondFormInputs],
   "e_other_assets": data[`e_other_assets_2022` as keyof SecondFormInputs],
 }
      return putQuery;
    }
  }
}

/*********************************************************************************** */
/** 1-st and 2-nd form POST, PATCH, PUT Requests */
/*********************************************************************************** */

export const saveFirstFormData = async (
  data: FirstFormInputs,
  token: string
) => {
  // prettier-ignore
  const res = await fetch(BUSINESS_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      "form_of_business": data.form_of_business,
      "sector_1": data.sector_1,
      "sector_2": data.sector_2,
      "sector_3": data.sector_3,
      "employment_size": data.employment_size,
      "headquarter": data.headquarter,
      "departments": data.departments,
      "eu_departments": data.eu_departments,
      "intercontinental_departments": data.intercontinental_departments,
      "market": data.market,
      "capital_debit": data.capital_debit,
      "accounting": data.accounting,
      "licensed_activity": data.licensed_activity,
      "company_import": data.company_import,
       
       
      // prettier-ignore
      "ecommerce": data.ecommerce
    })
  })// prettier-ignore
    .then((response) => {
      if (!response.ok) {
        // try PUT here
        if (response.status === 409) {
          try {
             
            const patchRes = fetch(BUSINESS_API_URL, {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                "form_of_business": data.form_of_business,
                "sector_1": data.sector_1,
                "sector_2": data.sector_2,
                "sector_3": data.sector_3,
                "employment_size": data.employment_size,
                "headquarter": data.headquarter,
                "departments": data.departments,
                "eu_departments": data.eu_departments,
                "intercontinental_departments": data.intercontinental_departments,
                "market": data.market,
                "capital_debit": data.capital_debit,
                "accounting": data.accounting,
                "licensed_activity": data.licensed_activity,
                "company_import": data.company_import,
                 
                // prettier-ignore
                "ecommerce": data.ecommerce
              }),
            }).then((response) => {
              if (!response.ok) {
                toast.error('Wystąpił błąd');
              } else {
                toast.success('Dane zostały zaktualizowane');
                response.json();
              }
            });
            console.log(patchRes);
          } catch {
            console.log(
              'Wystąpił błąd ' + response.status + ' ' + response.statusText
            );
          }
        }
      } else {
        toast.success('Dane zostały zapisane');
        console.log(res);
        response.json();
      }
    })
    .then((data) => {
      return data;
    });
};

export const saveSocondFormData = async (
  data: SecondFormInputs,
  token: string
) => {
  // prettier-ignore
  const postData = await prepareQuery(data, "POST");
  //const putData = await prepareQuery(data, 'PUT', 2020);

  console.log('postData', postData);
  const response = await fetch(FINANCIAL_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  if (!response.ok && response.status === 409) {
    try {
      console.log('data', data);
      putRequest(data, token, 2020);
      putRequest(data, token, 2021);
      putRequest(data, token, 2022);
    } catch {
      //toast.error('Wystąpił błąd');
      console.log(
        'Wystąpił błąd z automatycznym pobraniem danych formularza drugiego'
      );
    }
  } else if (response.ok) {
    toast.success(`Dane zostały zapisane`);
    return response.json();
  } else {
    console.log(`Wystąpił błąd ${response.status} ${response.statusText}`);
  }
};

export const putRequest = async (
  data: SecondFormInputs,
  token: string,
  year: number
) => {
  const putData = await prepareQuery(data, 'PUT', year);
  const putResponse = await fetch(FINANCIAL_API_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(putData),
  });

  if (putResponse.ok) {
    return putResponse.json();
  } else {
    console.log(
      `Wystąpił błąd ${putResponse.status} ${putResponse.statusText}`
    );
  }
};

/*********************************************************************************** */
/** 1-st and 2-nd form GET Requests */
/*********************************************************************************** */
export const fetchFirstFormData = async (token: string) => {
  const data = await fetch(BUSINESS_API_URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        console.log('Wystąpił błąd z pobieraniem danych pierwszego formularza');
        return;
      } else {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    });
  return data;
};

export const fetchSecondFormData = async (token: string) => {
  const defaultData: SecondFormInputs = {
    net_revenue_2020: 0,
    operating_profit_2020: 0,
    depreciation_2020: 0,
    total_cash_2020: 0,
    commitments_2020: 0,
    three_month_salary_budget_2020: 0,
    receivables_not_overdue_2020: 0,
    supplies_2020: 0,
    fixed_assets_2020: 0,
    a_lands_2020: 0,
    b_buildings_2020: 0,
    c_technical_infrastructure_2020: 0,
    d_transport_2020: 0,
    e_other_assets_2020: 0,
    net_revenue_2021: 0,
    operating_profit_2021: 0,
    depreciation_2021: 0,
    total_cash_2021: 0,
    commitments_2021: 0,
    three_month_salary_budget_2021: 0,
    receivables_not_overdue_2021: 0,
    supplies_2021: 0,
    fixed_assets_2021: 0,
    a_lands_2021: 0,
    b_buildings_2021: 0,
    c_technical_infrastructure_2021: 0,
    d_transport_2021: 0,
    e_other_assets_2021: 0,
    net_revenue_2022: 0,
    operating_profit_2022: 0,
    depreciation_2022: 0,
    total_cash_2022: 0,
    commitments_2022: 0,
    three_month_salary_budget_2022: 0,
    receivables_not_overdue_2022: 0,
    supplies_2022: 0,
    fixed_assets_2022: 0,
    a_lands_2022: 0,
    b_buildings_2022: 0,
    c_technical_infrastructure_2022: 0,
    d_transport_2022: 0,
    e_other_assets_2022: 0,
  };

  const data = await fetch(FINANCIAL_API_URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        console.log('Wystąpił błąd z pobieraniem danych drugiego formularza');
        return defaultData;
      } else {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    });
  return data;
};
// TODO: refactor this function
export const prefillSecondFormData = async (token: string) => {
  const data = await fetchSecondFormData(token).then((data) => {
    //console.log('psfd', data);

    let dataPart2020: {
      net_revenue_2020: SecondFormInputs['net_revenue_2020'];
      operating_profit_2020: SecondFormInputs['operating_profit_2020'];
      depreciation_2020: SecondFormInputs['depreciation_2020'];
      total_cash_2020: SecondFormInputs['total_cash_2020'];
      commitments_2020: SecondFormInputs['commitments_2020'];
      three_month_salary_budget_2020: SecondFormInputs['three_month_salary_budget_2020'];
      receivables_not_overdue_2020: SecondFormInputs['receivables_not_overdue_2020'];
      supplies_2020: SecondFormInputs['supplies_2020'];
      fixed_assets_2020: SecondFormInputs['fixed_assets_2020'];
      a_lands_2020: SecondFormInputs['a_lands_2020'];
      b_buildings_2020: SecondFormInputs['b_buildings_2020'];
      c_technical_infrastructure_2020: SecondFormInputs['c_technical_infrastructure_2020'];
      d_transport_2020: SecondFormInputs['d_transport_2020'];
      e_other_assets_2020: SecondFormInputs['e_other_assets_2020'];
    } = {
      net_revenue_2020: 0,
      operating_profit_2020: 0,
      depreciation_2020: 0,
      total_cash_2020: 0,
      commitments_2020: 0,
      three_month_salary_budget_2020: 0,
      receivables_not_overdue_2020: 0,
      supplies_2020: 0,
      fixed_assets_2020: 0,
      a_lands_2020: 0,
      b_buildings_2020: 0,
      c_technical_infrastructure_2020: 0,
      d_transport_2020: 0,
      e_other_assets_2020: 0,
    };
    let dataPart2021: {
      net_revenue_2021: SecondFormInputs['net_revenue_2021'];
      operating_profit_2021: SecondFormInputs['operating_profit_2021'];
      depreciation_2021: SecondFormInputs['depreciation_2021'];
      total_cash_2021: SecondFormInputs['total_cash_2021'];
      commitments_2021: SecondFormInputs['commitments_2021'];
      three_month_salary_budget_2021: SecondFormInputs['three_month_salary_budget_2021'];
      receivables_not_overdue_2021: SecondFormInputs['receivables_not_overdue_2021'];
      supplies_2021: SecondFormInputs['supplies_2021'];
      fixed_assets_2021: SecondFormInputs['fixed_assets_2021'];
      a_lands_2021: SecondFormInputs['a_lands_2021'];
      b_buildings_2021: SecondFormInputs['b_buildings_2021'];
      c_technical_infrastructure_2021: SecondFormInputs['c_technical_infrastructure_2021'];
      d_transport_2021: SecondFormInputs['d_transport_2021'];
      e_other_assets_2021: SecondFormInputs['e_other_assets_2021'];
    } = {
      net_revenue_2021: 0,
      operating_profit_2021: 0,
      depreciation_2021: 0,
      total_cash_2021: 0,
      commitments_2021: 0,
      three_month_salary_budget_2021: 0,
      receivables_not_overdue_2021: 0,
      supplies_2021: 0,
      fixed_assets_2021: 0,
      a_lands_2021: 0,
      b_buildings_2021: 0,
      c_technical_infrastructure_2021: 0,
      d_transport_2021: 0,
      e_other_assets_2021: 0,
    };
    let dataPart2022: {
      net_revenue_2022: SecondFormInputs['net_revenue_2022'];
      operating_profit_2022: SecondFormInputs['operating_profit_2022'];
      depreciation_2022: SecondFormInputs['depreciation_2022'];
      total_cash_2022: SecondFormInputs['total_cash_2022'];
      commitments_2022: SecondFormInputs['commitments_2022'];
      three_month_salary_budget_2022: SecondFormInputs['three_month_salary_budget_2022'];
      receivables_not_overdue_2022: SecondFormInputs['receivables_not_overdue_2022'];
      supplies_2022: SecondFormInputs['supplies_2022'];
      fixed_assets_2022: SecondFormInputs['fixed_assets_2022'];
      a_lands_2022: SecondFormInputs['a_lands_2022'];
      b_buildings_2022: SecondFormInputs['b_buildings_2022'];
      c_technical_infrastructure_2022: SecondFormInputs['c_technical_infrastructure_2022'];
      d_transport_2022: SecondFormInputs['d_transport_2022'];
      e_other_assets_2022: SecondFormInputs['e_other_assets_2022'];
    } = {
      net_revenue_2022: 0,
      operating_profit_2022: 0,
      depreciation_2022: 0,
      total_cash_2022: 0,
      commitments_2022: 0,
      three_month_salary_budget_2022: 0,
      receivables_not_overdue_2022: 0,
      supplies_2022: 0,
      fixed_assets_2022: 0,
      a_lands_2022: 0,
      b_buildings_2022: 0,
      c_technical_infrastructure_2022: 0,
      d_transport_2022: 0,
      e_other_assets_2022: 0,
    };

    for (let i = 0; i < data.length; i++) {
      //console.log('psfd', data[i].year);
      if (data[i].year === 2020) {
        // prettier-ignore
        dataPart2020 = {
          "net_revenue_2020": data[i].net_revenue,
          "operating_profit_2020": data[i].operating_profit,
          "depreciation_2020": data[i].depreciation,
          "total_cash_2020": data[i].total_cash,
          "commitments_2020": data[i].commitments,
          "three_month_salary_budget_2020": data[i].three_month_salary_budget,
          "receivables_not_overdue_2020": data[i].receivables_not_overdue,
          "supplies_2020": data[i].supplies,
          "fixed_assets_2020": data[i].fixed_assets,
          "a_lands_2020": data[i].a_lands,
          "b_buildings_2020": data[i].b_buildings,
          "c_technical_infrastructure_2020":
            data[i].c_technical_infrastructure,
          "d_transport_2020": data[i].d_transport,
          "e_other_assets_2020": data[i].e_other_assets,
        };
      }
      if (data[i].year === 2021) {
        // prettier-ignore
        dataPart2021 = {
          "net_revenue_2021": data[i].net_revenue,
          "operating_profit_2021": data[i].operating_profit,
          "depreciation_2021": data[i].depreciation,
          "total_cash_2021": data[i].total_cash,
          "commitments_2021": data[i].commitments,
          "three_month_salary_budget_2021": data[i].three_month_salary_budget,
          "receivables_not_overdue_2021": data[i].receivables_not_overdue,
          "supplies_2021": data[i].supplies,
          "fixed_assets_2021": data[i].fixed_assets,
          "a_lands_2021": data[i].a_lands,
          "b_buildings_2021": data[i].b_buildings,
          "c_technical_infrastructure_2021":
            data[i].c_technical_infrastructure,
          "d_transport_2021": data[i].d_transport,
          "e_other_assets_2021": data[i].e_other_assets,
        };
      }
      if (data[i].year === 2022) {
        // prettier-ignore
        dataPart2022 = {
          "net_revenue_2022": data[i].net_revenue,
          "operating_profit_2022": data[i].operating_profit,
          "depreciation_2022": data[i].depreciation,
          "total_cash_2022": data[i].total_cash,
          "commitments_2022": data[i].commitments,
          "three_month_salary_budget_2022": data[i].three_month_salary_budget,
          "receivables_not_overdue_2022": data[i].receivables_not_overdue,
          "supplies_2022": data[i].supplies,
          "fixed_assets_2022": data[i].fixed_assets,
          "a_lands_2022": data[i].a_lands,
          "b_buildings_2022": data[i].b_buildings,
          "c_technical_infrastructure_2022":
            data[i].c_technical_infrastructure,
          "d_transport_2022": data[i].d_transport,
          "e_other_assets_2022": data[i].e_other_assets,
        }
      }
    }
    // prettier-ignore
    const preparedData : SecondFormInputs = {
    "net_revenue_2020": dataPart2020.net_revenue_2020,
    "operating_profit_2020": dataPart2020.operating_profit_2020,
    "depreciation_2020": dataPart2020.depreciation_2020,
    "total_cash_2020": dataPart2020.total_cash_2020,
    "commitments_2020": dataPart2020.commitments_2020,
    "three_month_salary_budget_2020": dataPart2020.three_month_salary_budget_2020,
    "receivables_not_overdue_2020": dataPart2020.receivables_not_overdue_2020,
    "supplies_2020": dataPart2020.supplies_2020,
    "fixed_assets_2020": dataPart2020.fixed_assets_2020,
    "a_lands_2020": dataPart2020.a_lands_2020,
    "b_buildings_2020": dataPart2020.b_buildings_2020,
    "c_technical_infrastructure_2020":
    dataPart2020.c_technical_infrastructure_2020,
    "d_transport_2020": dataPart2020.d_transport_2020,
    "e_other_assets_2020": dataPart2020.e_other_assets_2020,
    "net_revenue_2021": dataPart2021.net_revenue_2021,
    "operating_profit_2021": dataPart2021.operating_profit_2021,
    "depreciation_2021": dataPart2021.depreciation_2021,
    "total_cash_2021": dataPart2021.total_cash_2021,
    "commitments_2021": dataPart2021.commitments_2021,
    "three_month_salary_budget_2021": dataPart2021.three_month_salary_budget_2021,
    "receivables_not_overdue_2021": dataPart2021.receivables_not_overdue_2021,
    "supplies_2021": dataPart2021.supplies_2021,
    "fixed_assets_2021": dataPart2021.fixed_assets_2021,
    "a_lands_2021": dataPart2021.a_lands_2021,
    "b_buildings_2021": dataPart2021.b_buildings_2021,
    "c_technical_infrastructure_2021":
    dataPart2021.c_technical_infrastructure_2021,
    "d_transport_2021": dataPart2021.d_transport_2021,
    "e_other_assets_2021": dataPart2021.e_other_assets_2021,
    "net_revenue_2022": dataPart2022.net_revenue_2022,
    "operating_profit_2022": dataPart2022.operating_profit_2022,
    "depreciation_2022": dataPart2022.depreciation_2022,
    "total_cash_2022": dataPart2022.total_cash_2022,
    "commitments_2022": dataPart2022.commitments_2022,
    "three_month_salary_budget_2022": dataPart2022.three_month_salary_budget_2022,
    "receivables_not_overdue_2022": dataPart2022.receivables_not_overdue_2022,
    "supplies_2022": dataPart2022.supplies_2022,
    "fixed_assets_2022": dataPart2022.fixed_assets_2022,
    "a_lands_2022": dataPart2022.a_lands_2022,
    "b_buildings_2022": dataPart2022.b_buildings_2022,
    "c_technical_infrastructure_2022":
    dataPart2022.c_technical_infrastructure_2022,
    "d_transport_2022": dataPart2022.d_transport_2022,
    "e_other_assets_2022": dataPart2022.e_other_assets_2022,
  };

    return preparedData;
  });
  return data;
};

/*********************************************************************************** */
/** 2-nd for fixed_assets fetch update */
/*********************************************************************************** */
export const fetchFixedAssets = async (token: string) => {
  const data = await fetch(FINANCIAL_API_URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        console.log(
          'Wystąpił błąd z pobieraniem subtotali w drugim formularzu'
        );
        return;
      } else {
        return response.json();
      }
    })
    .then((data) => {
      return data;
    });
  return data;
};
