import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthInfo } from '@propelauth/react';

import { HeaderContext } from '../../store/HeaderContext';

import logo from './GrowHub.svg';
import { areFormsFilled } from './useApi';
export default function ModernHeader() {
  const [isHamburgerOnMobileOpen, setisHamburgerOnMobileOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [formsAreFilled, setFormsAreFilled] = useState(true);

  const useAuth = useAuthInfo();
  const { pickedForm, setPickedForm } = useContext(HeaderContext);

  async function fetchRaportData() {
    try {
      const data = await areFormsFilled(useAuth.accessToken as string);
      setFormsAreFilled(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (typeof useAuth?.user?.properties?.metadata !== 'undefined') {
      const isAdmin = (
        useAuth.user.properties.metadata as { isAdmin?: boolean }
      )?.isAdmin;
      if (isAdmin) {
        setIsAdmin(true);
      }
    }
  }, [
    isAdmin,
    useAuth?.user?.properties?.isAdmin,
    useAuth?.user?.properties?.metadata,
  ]);

  useEffect(() => {
    fetchRaportData();
  }, []);

  return (
    <div>
      <div className='bg-white left-0 right-0 z-30 transition-all duration-300 ease-linear top-0 sticky translate-y-0'>
        <header
          className='bg-white flex flex-col justify-center border-b border-sh-border-color text-gray-80'
          id='application-header'
        >
          <div className='bg-[#85bb65]/20 w-full'>
            <div className='relative flex items-center justify-between px-5 w-full max-w-content mx-auto container'>
              <div className='hidden lg:flex shrink-0 py-2.5 ml-auto'>
                <Link
                  className='flex items-center font-normal text-sm'
                  to='mailto:kontakt@growhub.com.pl'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    fill='currentColor'
                    className='w-6 h-6 pr-1'
                  >
                    <path d='M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z' />
                    <path d='M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z' />
                  </svg>
                  kontakt@growhub.com.pl
                </Link>
              </div>
            </div>
          </div>
          <div className='relative flex items-center justify-center lg:justify-between px-5 lg:px-0 2xl:px-5 w-full max-w-content mx-auto h-[55px] md:h-[72px] container'>
            <div className='flex items-center justify-between w-full md:w-auto '>
              <div className='application-logo shrink-0'>
                <Link
                  className=''
                  id='ga_menu-top_logo'
                  aria-label='Strona główna'
                  data-testid='Menu--logo'
                  to='https://growhub.com.pl/'
                >
                  <img
                    src={logo}
                    alt='logo'
                    className='w-[130px] md:w-[200px]'
                  />
                </Link>
              </div>
              <div className='flex items-center space-x-6'>
                <Link
                  className='flex items-center font-normal text-sm lg:hidden'
                  to='mailto:kontakt@growhub.com.pl'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    fill='currentColor'
                    className='w-6 h-6 pr-1'
                  >
                    <path d='M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z' />
                    <path d='M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z' />
                  </svg>
                </Link>
                <button
                  className='LayoutHamburger_hamburger__R7alb h-[50px]  inline-block lg:hidden'
                  type='button'
                  aria-label='Otwórz menu'
                  onClick={() =>
                    setisHamburgerOnMobileOpen(!isHamburgerOnMobileOpen)
                  }
                >
                  <svg fill='none' viewBox='0 0 15 15' height='1em' width='1em'>
                    <path
                      fill='currentColor'
                      fillRule='evenodd'
                      d='M1.5 3a.5.5 0 000 1h12a.5.5 0 000-1h-12zM1 7.5a.5.5 0 01.5-.5h12a.5.5 0 010 1h-12a.5.5 0 01-.5-.5zm0 4a.5.5 0 01.5-.5h12a.5.5 0 010 1h-12a.5.5 0 01-.5-.5z'
                      clipRule='evenodd'
                    />
                  </svg>
                </button>
              </div>
            </div>
            <nav className='hidden lg:flex h-full'>
              <ul className='flex h-full'>
                <li className='group relative flex h-full items-center'>
                  <Link
                    className='block px-4 text-base 2xl:leading-10 transition-colors duration-300 ease-in-out xl:px-5'
                    to='/'
                    onClick={() => setPickedForm('business_form')}
                  >
                    <div className='pointer-events-none absolute bottom-0 left-0 h-1 w-full rounded-t-xl bg-[#85bb65] opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100'></div>
                    <span>Informacje o działalności</span>
                  </Link>
                </li>

                {formsAreFilled && (
                  <>
                    <li className='group relative flex h-full items-center '>
                      <Link
                        className='block px-4 text-base 2xl:leading-10 transition-colors duration-300 ease-in-out xl:px-5'
                        to='/financial_data'
                      >
                        <div className='pointer-events-none absolute bottom-0 left-0 h-1 w-full rounded-t-xl bg-[#85bb65] opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100'></div>
                        <span>Dane finansowe</span>
                      </Link>
                    </li>
                    <li className='group relative flex h-full items-center'>
                      <Link
                        className='block px-4 text-base 2xl:leading-10 transition-colors duration-300 ease-in-out lg:px-5'
                        to='/dashboard'
                      >
                        <div className='pointer-events-none absolute bottom-0 left-0 h-1 w-full rounded-t-xl bg-[#85bb65] opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100'></div>
                        <span>Dashboard</span>
                      </Link>
                    </li>
                  </>
                )}
                <li className='group relative flex h-full items-center'>
                  <Link
                    className='block px-4 text-base 2xl:leading-10 transition-colors duration-300 ease-in-out lg:px-5'
                    to='/book_consultation'
                  >
                    <div className='pointer-events-none absolute bottom-0 left-0 h-1 w-full rounded-t-xl bg-[#85bb65] opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100'></div>
                    <Link
                      className='block px-4 text-base 2xl:leading-10 transition-colors duration-300 ease-in-out lg:px-5'
                      to='/book_consultation'
                    >
                      <div className='pointer-events-none absolute bottom-0 left-0 h-1 w-full rounded-t-xl bg-[#85bb65] opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100'></div>
                      <span>Konsultacje</span>
                    </Link>
                  </Link>
                </li>

                <li className='group relative flex h-full items-center'>
                  {isAdmin && (
                    <Link
                      className='block px-4 text-base 2xl:leading-10 transition-colors duration-300 ease-in-out lg:px-5'
                      to='/settings/AdminPanel'
                    >
                      <div className='pointer-events-none absolute bottom-0 left-0 h-1 w-full rounded-t-xl bg-[#85bb65] opacity-0 transition-opacity duration-300 ease-in-out group group-hover:opacity-100'></div>
                      <span>Panel Administratora</span>
                    </Link>
                  )}
                </li>
              </ul>
            </nav>
            <div className='LayoutHeaderUserNav_mainNavItemUserMenu__B3keu group relative ml-6 hidden h-full items-center md:flex'></div>
          </div>
        </header>
        <div className={isHamburgerOnMobileOpen ? '' : 'hidden'}>
          <div
            className='absolute bg-white left-0 right-0 shadow-xl pt-1 border border-gray10 rounded-b-2xl text-base z-50 overflow-y-scroll LayoutHeaderMobileNav_mobileMenu__7aZxR'
            id='mobile-menu'
          >
            <nav className='px-8 md:px-6'>
              <div className='relative'>
                <button
                  type='button'
                  className='flex items-center cursor-pointer focus:outline-none w-full'
                >
                  <div className='mr-4 transform ease-in-out duration-200 w-full'>
                    <Link
                      className='block py-3 tracking-wide text-gray80 w-full text-left'
                      to='/'
                      onClick={() => setPickedForm('business_form')}
                    >
                      Informacje o działalności
                    </Link>
                  </div>
                </button>
                <button
                  type='button'
                  className='flex items-center cursor-pointer focus:outline-none w-full'
                >
                  <div className='mr-4 transform ease-in-out duration-200 w-full'>
                    <Link
                      className='block py-3 tracking-wide text-gray80 w-full text-left'
                      to='/'
                      onClick={() => setPickedForm('financial_form')}
                    >
                      Dane finansowe
                    </Link>
                  </div>
                </button>
              </div>
              <div className='relative'>
                <button
                  type='button'
                  className='flex items-center cursor-pointer focus:outline-none w-full'
                >
                  <div className='mr-4 transform ease-in-out duration-200 w-full'>
                    <Link
                      className='block py-3 tracking-wide text-gray80 w-full text-left'
                      to='/dashboard'
                    >
                      Dashboard
                    </Link>
                  </div>
                </button>
              </div>
              <div className='relative'>
                <Link
                  className='block py-3 tracking-wide text-gray80 w-full text-left'
                  to='/book_consultation'
                >
                  Konsultacje
                </Link>
              </div>
              <div className='relative'>
                {isAdmin && (
                  <Link
                    className='block py-3 tracking-wide text-gray80 w-full text-left'
                    to='/settings/AdminPanel'
                  >
                    Panel administratora
                  </Link>
                )}
              </div>
            </nav>
            <Link
              className='block text-[#85bb65] font-semibold border-t-2 border-gray10 py-6 text-center'
              to='/dashboard'
            >
              Twoje konto
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
