import {
  fetchFirstFormData,
  fetchSecondFormData,
} from '../CustomForm/ApiService';

export const areFormsFilled = async (key: string) => {
  try {
    const firstForm = await fetchFirstFormData(key);
    const secondForm = await fetchSecondFormData(key);
    if (firstForm && secondForm) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
