import React from 'react';
import { Box, Container } from '@mui/joy';

import ModernHeader from '../../components/Header/ModernHeader';

type Props = {
  children: React.ReactNode;
};

export default function BasicAppPageWithSidebarTemplate({ children }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ModernHeader />
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              justifyContent: 'space-between',
              // background: '#FAF9FA',
              height: 'calc(95vh-500px)',
              paddingTop: '16px',
              paddingBottom: '16px',
            }}
          >
            <Box>
              <Container maxWidth={'xl'}>{children}</Container>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
