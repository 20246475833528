import { Route, Routes } from 'react-router-dom';

import AdminPanelPage from './pages/AdminPanelPage';
import BookConsultationPage from './pages/dashboard/BookConsultationPage';
import EvaluatePage from './pages/dashboard/EvaluatePage';
import DashboardPage from './pages/DashboardPage';
import { NotFound } from './pages/NotFound';
import SummaryPage from './pages/SummaryPage';

export const Router = () => {
  return (
    <Routes>
      <Route path='' element={<EvaluatePage currentTab={1} />} />
      <Route path='/financial_data' element={<EvaluatePage currentTab={2} />} />
      <Route path='/summary' element={<SummaryPage />} />
      <Route path='/book_consultation' element={<BookConsultationPage />} />
      <Route path='/dashboard' element={<DashboardPage />} />
      <Route path='/settings/AdminPanel' element={<AdminPanelPage />} />
      <Route path='/admin_panel' element={<AdminPanelPage />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};
